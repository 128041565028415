import React from "react";
import { SOMETHING_WENT_WRONG_TEXT } from "../../../utils/constants/GlobalConstants";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1 className="text-center">{SOMETHING_WENT_WRONG_TEXT}</h1>;
    }

    return this.props.children;
  }
}
