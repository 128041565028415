import { useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import "./ImageMagnify.css";

const ImageMagnify = ({ src, alt }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMouseMove = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    setMousePosition({ x: offsetX, y: offsetY });
  };
  return (
    <div
      className="image-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseMove={handleMouseMove}
    >
      <img
        src={src}
        alt={alt}
        className={isMobile ? "imageForMobile" : "image"}
      />
      {isHovered && (
        <div
          className="magnified-image"
          style={{
            backgroundImage: `url(${src})`,
            backgroundPosition: `${(mousePosition.x / 330) * 100}% ${
              (mousePosition.y / 330) * 100
            }%`,
          }}
        />
      )}
    </div>
  );
};
export default ImageMagnify;
