export const APPLICATION_LOOKUP_DROPDOWN_DATA = [
  { id: 1, label: "Auto/Light Trucks", value: "Auto/Light Trucks" },
  { id: 2, label: "Medium/Heavy Duty", value: "Medium/Heavy Duty" },
  { id: 3, label: "Recreational/Marine", value: "Recreational/Marine" },
  {
    id: 4,
    label: "Off Highway / Industrial",
    value: "Off Highway / Industrial",
  },
  {
    id: 5,
    label: "Agriculture/Farm/Lawn & Garden",
    value: "Agriculture/Farm/Lawn & Garden",
  },
  { id: 6, label: "ATV", value: "ATV" },
  { id: 7, label: "Motorcycle", value: "Motorcycle" },
  { id: 8, label: "Snowmobiles", value: "Snowmobiles" },
  { id: 9, label: "Personal Water Craft", value: "Personal Water Craft" },
  { id: 10, label: "Utility Vehicles", value: "Utility Vehicles" },
];

export const FILTER_BY_STYLE_DROPDOWN_DATA = [
  {
    id: 1,
    label: "Round/ Oval shaped Air Filter",
    name: "Round/ Oval shaped Air Filter",
  },
  { id: 2, label: "Panel Style Air Filters", name: "Panel Style Air Filters" },
  {
    id: 3,
    label: "Spin-on Air/Breather Filter",
    name: "Spin-on Air/Breather Filter",
  },
  {
    id: 4,
    label: "Sponge media air filters",
    name: "Sponge media air filters",
  },
];

export const COMPETITOR_INTERCHANGE_DATA = [
  {
    id: 1,
    value: "",
    valid: true,
  },
  {
    id: 2,
    value: "",
    valid: true,
  },
  {
    id: 3,
    value: "",
    valid: true,
  },
  {
    id: 4,
    value: "",
    valid: true,
  },
  {
    id: 5,
    value: "",
    valid: true,
  },
  {
    id: 6,
    value: "",
    valid: true,
  },
  {
    id: 7,
    value: "",
    valid: true,
  },
  {
    id: 8,
    value: "",
    valid: true,
  },
  {
    id: 9,
    value: "",
    valid: true,
  },
  {
    id: 10,
    value: "",
    valid: true,
  },
];

export const PDF_SECTION_ITEMS = [
  {
    id: 1,
    name: "Off Highway",
    url: "https://www.napaecatalog.com/data/oha20flx/011/html/export/NAPA%20Filters%20Heavy%20Duty%20-%20Off%20Highway.pdf",
  },
  {
    id: 2,
    name: "Over the Road",
    url: "https://www.napaecatalog.com/data/htb20flx/011/html/export/NAPA%20Filters%20Heavy%20Duty%20Truck%20&%20Bus%20Applications%20Catalog.pdf",
  },
  {
    id: 3,
    name: "Pass Car Light Truck",
    url: "https://www.napaecatalog.com/data/ldf23flx/011/html/export/NAPA%20Filters%20Automotive%20and%20Light%20Duty%20Trucks.pdf",
  },
  {
    id: 4,
    name: "Racing",
    url: "https://www.napaecatalog.com/data/fvn16flx/011/html/export/NAPA%20Filters%20Vintage%20Catalog%201939-2000%20Automotive%20and%20Light%20Duty%20Trucks.pdf",
  },
  {
    id: 5,
    name: "Power Sports",
  },
  {
    id: 6,
    name: "Reference Guide",
    url: "https://www.napaecatalog.com/data/fic19flx/011/html/export/NAPA%20Filters%20Reference%20Guide.pdf",
  },
];

export const QUICK_SEARCH_HEADERS = [
  { id: "yearMakeModel", numeric: false, label: "Year Make Model" },
  { id: "qualifier", numeric: false, label: "Qualifier" },
  { id: "oil", numeric: false, label: "Oil" },
  { id: "fuel", numeric: false, label: "Fuel" },
  { id: "air", numeric: false, label: "Air" },
  { id: "cAir", numeric: false, label: "C Air" },
  { id: "trans", numeric: false, label: "Trans" },
  { id: "breather", numeric: false, label: "Breather" },
  { id: "hydraulic", numeric: false, label: "Hydraulic" },
  { id: "others", numeric: false, label: "Others" },
  { id: "loc", numeric: false, label: "Loc" },
];

export const PARTIAL_SEARCH_HEADERS = [
  { id: "partialPartNumber", numeric: false, label: "Part Number" },
  { id: "manufacturer", numeric: false, label: "Manufacturer" },
  { id: "abbreviation", numeric: false, label: "Abbr." },
  { id: "napaPartNumber", numeric: false, label: "Napa Part Number" },
  { id: "leadTime", numeric: false, label: "Lead Time" },
];
export const DYNAMIC_SEARCH_HEADERS = [
  { id: "searchNumber", numeric: false, label: "Search No." },
  { id: "name", numeric: false, label: "Name" },
  { id: "napaPartNumber", numeric: false, label: "NAPA" },
];

export const FILTER_SEARCH_HEADERS = [
  {
    id: "partNumber",
    numeric: false,
    label: "Part Number",
  },
  {
    id: "manufacturer",
    numeric: false,
    label: "Manufacturer",
  },
  {
    id: "abbreviation",
    numeric: false,
    label: "Abbr.",
  },
  {
    id: "napaPartNumber",
    numeric: false,
    label: "Napa Part Number",
  },
  {
    id: "online",
    numeric: false,
    label: " ",
  },
  {
    id: "leadTime",
    numeric: false,
    label: "Lead Time",
  },
];

export const VIN_SEARCH_HEADERS = [
  {
    id: "productDescription",
    numeric: false,
    label: "Product",
  },
  {
    id: "napaPartNumber",
    numeric: false,
    label: "Part Number",
  },
  {
    id: "shop",
    numeric: false,
    label: "",
  },
  {
    id: "images",
    numeric: false,
    label: "Images",
  },
  {
    id: "fitmentNote",
    numeric: false,
    label: "Fitment Note",
  },
];

export const APP_LOOKUP_HEADERS = [
  {
    id: "MakeModel",
    numeric: false,
    label: "MakeModel",
  },
  {
    id: "Qualifier",
    numeric: false,
    label: "Qualifier",
  },

  {
    id: "Oil",
    numeric: false,
    label: "Oil",
  },
  {
    id: "Fuel",
    numeric: false,
    label: "Fuel",
  },
  {
    id: "Air",
    numeric: false,
    label: "Air",
  },
  {
    id: "CAir",
    numeric: false,
    label: "C Air",
  },
  {
    id: "Trans",
    numeric: false,
    label: "Trans",
  },
  {
    id: "Breather",
    numeric: false,
    label: "Breather",
  },
  {
    id: "Hydraulic",
    numeric: false,
    label: "Hydraulic",
  },
  {
    id: "Others",
    numeric: false,
    label: "Others",
  },
];
export const GASKET_DIAMETER_HEADERS = [
  {
    id: "gasketOutletDiameter",
    numeric: false,
    label: "Od",
  },
  {
    id: "gasketInsideDiameter",
    numeric: false,
    label: "Id",
  },
  {
    id: "gasketThickness",
    numeric: false,
    label: "Thk",
  },
];

export const PART_APP_HEADERS = [
  {
    id: "make",
    numeric: false,
    label: "Make",
  },
  {
    id: "model",
    numeric: false,
    label: "Model",
  },
  {
    id: "engine",
    numeric: false,
    label: "Engine",
  },
];
export const COMP_INTERCHANGE_LIST_HEADERS = [
  {
    id: "brand",
    numeric: false,
    label: "Competitor Name",
  },
  {
    id: "partNum",
    numeric: false,
    label: "Cross Reference",
  },
];

export const FILTER_BY_SIZE_HEADERS = [
  {
    id: "PartNumber",
    label: "Part Number",
  },

  {
    id: "Height",
    label: "Height (H)",
  },
  {
    id: "Length",
    label: "Length (L)",
  },
  {
    id: "Thread Size",
    label: "Thread Size (G)",
  },
  {
    id: "Width",
    label: "Width (W)",
  },
  {
    id: "Bottom ID",
    label: "Bottom ID (C)",
  },
  {
    id: "Bottom OD",
    label: "Bottom OD (D)",
  },
  {
    id: "Top OD",
    label: "Top OD (A)",
  },
  {
    id: "Top ID",
    label: "Top ID (B)",
  },

  {
    id: "Gasket Attached",
    label: "Gasket Attached",
  },
  {
    id: " Gasket ID",
    label: " Gasket ID",
  },
  {
    id: " Gasket OD",
    label: " Gasket OD",
  },
];
export const FILTER_BY_SIZE_HEADERS_ROUND = [
  {
    id: "partNumber",
    label: "Part Number",
  },

  {
    id: "height",
    label: "Height (H)",
  },
  {
    id: "bottomInsideDiameter",
    label: "Bottom ID (C)",
  },
  {
    id: "bottomOutsideDiameter",
    label: "Bottom OD (D)",
  },
  {
    id: "topOutsideDiameter",
    label: "Top OD (A)",
  },
  {
    id: "topInsideDiameter",
    label: "Top ID (B)",
  },
];

export const FILTER_BY_SIZE_HEADERS_PANEL = [
  {
    id: "partNumber",
    label: "Part Number",
  },

  {
    id: "height",
    label: "Height (H)",
  },
  {
    id: "length",
    label: "Length (A)",
  },
  {
    id: "width",
    label: "Width (B)",
  },
];
export const FILTER_BY_SIZE_HEADERS_SPINON = [
  {
    id: "partNumber",
    label: "Part Number",
  },

  {
    id: "height",
    label: "Height (H)",
  },
  {
    id: "threadSize",
    label: "Thread Size (G)",
  },
  {
    id: "topOutsideDiameter",
    label: "Top OD (A)",
  },

  {
    id: "gasketIncluded",
    label: "Gasket Attached",
  },
  {
    id: "gasketInsideDiameter",
    label: " Gasket ID",
  },
  {
    id: "gasketOutletDiameter",
    label: " Gasket OD",
  },
];
export const FILTER_BY_SIZE_HEADERS_CATRIDGE = [
  {
    id: "partNumber",
    label: "Part Number",
  },

  {
    id: "height",
    label: "Height (H)",
  },
  {
    id: "bottomInsideDiameter",
    label: "Bottom ID (C)",
  },
  {
    id: "bottomOutsideDiameter",
    label: "Bottom OD (D)",
  },
  {
    id: "topOutsideDiameter",
    label: "Top OD (A)",
  },
  {
    id: "topInsideDiameter",
    label: "Top ID (B)",
  },
];
export const FILTER_BY_SIZE_HEADERS_INLINE = [
  {
    id: "partNumber",
    label: "Part Number",
  },

  {
    id: "height",
    label: "Height (H)",
  },
  {
    id: "topOutsideDiameter",
    label: "Top OD (A)",
  },
  {
    id: "inlet",
    label: "Inlet (F)",
  },
  {
    id: "outlet",
    label: "Inlet (G)",
  },
];
export const PRODUCT_DETAIL_NAV_ITEMS = [
  {
    menu: "Technical Bulletins",
    isActive: false,
  },
  {
    menu: "Instructions",
    isActive: false,
  },
  {
    menu: "Images",
    isActive: true,
  },
  {
    menu: "Videos",
    isActive: false,
  },
  {
    menu: "Prop 65",
    isActive: false,
  },
];

export const PRODUCTS_MAKES_DATA = [
  { id: 1, label: "Airsream", value: "Airsream" },
  { id: 2, label: "BMW", value: "BMW" },
  { id: 3, label: "Ford", value: "Ford" },
];

export const PRODUCTS_MODEL_DATA = [
  { id: 1, label: "A1", value: "A1" },
  { id: 2, label: "A3", value: "A3" },
  { id: 3, label: "A4", value: "A4" },
];

export const PRODUCT_PARTNUMBERS = [
  { id: 1, label: "2200268", value: "2200268" },
  { id: 2, label: "200866", value: "200866" },
  { id: 2, label: "200268", value: "200268" },
];

export const FILTER_BY_SIZE_DROPDOWN_DATA = [
  { id: 1, label: "Air Filter", value: "Air Filter" },
  { id: 2, label: "Cabin Air Filter", value: "Cabin Air Filter" },
  {
    id: 3,
    label: "Oil Filter",
    value: "Oil Filter",
  },
  {
    id: 7,
    label: "Fuel Filters",
    value: "Fuel Filters",
  },
];

//images
export const QUALIF_LABELS = {
  spec_thread_size: "Thread Size",
  spec_height: "Height",
  spec_length: "Length",
  spce_inlet: "Inlet",
};

export const IMG_MAP = {
  "Air Filter": {
    Round: [
      "AIR_RoundOval Shaped Air Filters1.gif",
      "AIR_RoundOval Shaped Air Filters2.gif",
    ],
    "Round Seal": ["AIR_RoundOval Shaped Air Filters1.gif"],
    "Round Panel": ["RoundPanel.jpg"],
    Cylindrical: ["AIR_RoundOval Shaped Air Filters2.gif"],
    Cone: ["AIR_RoundOval Shaped Air Filters2.gif"],
    "Spin-On": ["AIR_Spin_on Air_Breather Filter.gif"],
    Panel: [
      "AIR_Panel Style Air Filters1.gif",
      "AIR_Panel Style Air Filters2.gif",
    ],
    Cartridge: ["Coolant_Catridge2.gif", "Coolant_Catridge1.gif"],
  },
  "Cabin Air Filter": {
    Round: [
      "Cabin Air Filter_Cabin Air Filter_Round1.gif",
      "Cabin Air Filter_Cabin Air Filter_Round2.gif",
    ],
    "Spin-On": ["AIR_Spin_on Air_Breather Filter.gif"],
    Panel: [
      "Cabin Air Filter_Cabin Air Filter Panels1.gif",
      "Cabin Air Filter_Cabin Air Filter Panels2.gif",
    ],
    Cylindrical: ["AIR_RoundOval Shaped Air Filters2.gif"],
    Cone: ["LUBE_HYD_TRANS_Catridge2.gif"],
    Cartridge: ["LUBE_HYD_TRANS_Catridge2.gif", "LUBE_HYD_TRANS_Catridge1.gif"],
  },
  "Oil Filter": {
    Canister: ["LUBE_HYD_TRANS_Spin_on_lube.gif"],
    Cartridge: ["LUBE_HYD_TRANS_Catridge1.gif", "LUBE_HYD_TRANS_Catridge2.gif"],
    Round: ["AIR_RoundOval Shaped Air Filters1.gif"],
    "Spin-On": [
      "LUBE_HYD_TRANS_Oil_with.gif",
      "LUBE_HYD_TRANS_Spin_on_lube.gif",
    ],
  },
  "Hydraulic Filters": {
    Canister: ["LUBE_HYD_TRANS_Spin_on_lube.gif"],
    Cartridge: ["LUBE_HYD_TRANS_Catridge1.gif", "LUBE_HYD_TRANS_Catridge2.gif"],
    "Spin-On": [
      "LUBE_HYD_TRANS_Oil_with.gif",
      "LUBE_HYD_TRANS_Spin_on_lube.gif",
    ],
    "In-Line": [
      "Fuel_In_Line Style Fuel Filters1.gif",
      "Fuel_In_Line Style Fuel Filters2.gif",
      "Fuel_In_Line Style Fuel Filters3.gif",
    ],
  },
  "Power Steering Filter": {
    Canister: ["LUBE_HYD_TRANS_Spin_on_lube.gif"],
    Cartridge: ["LUBE_HYD_TRANS_Catridge1.gif", "LUBE_HYD_TRANS_Catridge2.gif"],
    "In-Line": [
      "Fuel_In_Line Style Fuel Filters1.gif",
      "Fuel_In_Line Style Fuel Filters2.gif",
      "Fuel_In_Line Style Fuel Filters3.gif",
    ],
    Panel: [
      "AIR_Panel Style Air Filters1.gif",
      "AIR_Panel Style Air Filters2.gif",
    ],
    Round: ["AIR_RoundOval Shaped Air Filters1.gif"],
    "Spin-On": [
      "LUBE_HYD_TRANS_Oil_with.gif",
      "LUBE_HYD_TRANS_Spin_on_lube.gif",
    ],
  },
  "Transmission Filters": {
    Cartridge: ["LUBE_HYD_TRANS_Catridge1.gif", "LUBE_HYD_TRANS_Catridge2.gif"],
    "In-Line": [
      "Fuel_In_Line Style Fuel Filters1.gif",
      "Fuel_In_Line Style Fuel Filters2.gif",
      "Fuel_In_Line Style Fuel Filters3.gif",
    ],
    Panel: [
      "AIR_Panel Style Air Filters1.gif",
      "AIR_Panel Style Air Filters2.gif",
    ],
    Round: ["AIR_RoundOval Shaped Air Filters1.gif"],
    "Spin-On": [
      "LUBE_HYD_TRANS_Oil_with.gif",
      "LUBE_HYD_TRANS_Spin_on_lube.gif",
    ],
  },
  "Fuel Filters": {
    Canister: ["LUBE_HYD_TRANS_Spin_on_lube.gif"],
    Cartridge: ["Fuel_Catridge fuel2.gif", "Fuel_Catridge fuel1.gif"],
    "In-Line": [
      "Fuel_In_Line Style Fuel Filters1.gif",
      "Fuel_In_Line Style Fuel Filters2.gif",
      "Fuel_In_Line Style Fuel Filters3.gif",
    ],
    "Spin-On": [
      "Fuel_Spin_on Fuel_FW Separator.gif",
      "Fuel_Spin_on FW Separator_Bowl1.gif",
      "Fuel_Spin_on FW Separator_Bowl2.gif",
    ],
  },
  "Fuel Water Separator Filter": {
    Canister: ["LUBE_HYD_TRANS_Spin_on_lube.gif"],
    Cartridge: ["Fuel_Catridge fuel2.gif", "Fuel_Catridge fuel1.gif"],
    "In-Line": [
      "Fuel_In_Line Style Fuel Filters1.gif",
      "Fuel_In_Line Style Fuel Filters2.gif",
      "Fuel_In_Line Style Fuel Filters3.gif",
    ],
    Round: ["AIR_RoundOval Shaped Air Filters1.gif"],
    "Spin-On": [
      "Fuel_Spin_on Fuel_FW Separator.gif",
      "Fuel_Spin_on FW Separator_Bowl1.gif",
      "Fuel_Spin_on FW Separator_Bowl2.gif",
    ],
  },
  "Emission Filters": {
    Cartridge: ["Fuel_Catridge fuel2.gif", "Fuel_Catridge fuel1.gif"],
    "In-Line": [
      "Fuel_In_Line Style Fuel Filters1.gif",
      "Fuel_In_Line Style Fuel Filters2.gif",
      "Fuel_In_Line Style Fuel Filters3.gif",
    ],
    "Spin-On": [
      "Fuel_Spin_on Fuel_FW Separator.gif",
      "Fuel_Spin_on FW Separator_Bowl1.gif",
      "Fuel_Spin_on FW Separator_Bowl2.gif",
    ],
  },
};
export const MAIL_CLIENTS = [
  { client: "gmail", src: require("../../assets/images/gmail1.png") },
  { client: "outlook", src: require("../../assets/images/outlook-logo.png") },
];
export const GMAIL = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=napapartshub@genpt.com?subject=${encodeURIComponent(
  "NapaPartsHub%20tool%20:%20Feedback"
)}`;
export const OUTLOOK = `https://outlook.office.com/mail/deeplink/compose?to=napapartshub@genpt.com?subject=${encodeURIComponent(
  "NapaPartsHub%20tool%20:%20Feedback"
)}`;
export const VALID_SEARCH_REGEX =
  /^(?!^[A-Za-z]+$)(?=.*[A-Za-z0-9])(?:(?!.*[.,/-]{2})(?!.*[.,/-]$)[a-zA-Z0-9.,/-])+$/;
export const NAPA_PROLINK_URL = process.env.REACT_APP_PRO_LINK;
export const NAPA_ONLINE_URL = process.env.REACT_APP_NAPA_ONLINE_URL;
export const TRACKER = '?impressionRank=1&flow=filterinterchange&cid=filterinterchange';
export const NAPA_MEDIA_URL =
  "https://media.napaonline.com/is/image/GenuinePartsCompany/NWMTMBLG?$Product=GenuinePartsCompany/";
