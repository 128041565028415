import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import {
  FONT_SIZE_115REM,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from "../../../utils/constants/GlobalConstants";

export const Theme = (props) => {
  let theme = createTheme({
    palette: {
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
      },
    },
    typography: {
      h6: {
        ...FONT_SIZE_115REM,
      },
    },
  });

  theme = responsiveFontSizes(theme);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
