import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { COLOR_FFF } from "../../../utils/constants/GlobalConstants";

export default function SimpleBackdrop(props) {
  const [open, setOpen] = React.useState(props.open);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props]);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Backdrop
        sx={(theme) => ({ ...COLOR_FFF, zIndex: theme.zIndex.drawer + 1 })}
        open={open}
        onClick={handleClose}
        testid={"backdrop"}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
