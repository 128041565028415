export const APP_TITLE = "FILTERS INTERCHANGE LOOK-UP TOOL";
export const PRIMARY_COLOR = "#2563eb";
export const SECONDARY_COLOR = "#232222";
export const COPYRIGHT_MESSAGE = "Copyright © 2024. All rights reserved";
export const SUB_COLOR = "#2563eb";
export const SEARCH_FILTER_PLACEHOLDER = "NAPA Part Number";
export const SEARCH_FILTER_PARTIAL_PLACEHOLDER = "Enter Partial Number";
export const GO_LABEL = "GO";
export const SEARCH_FILTER_MSG =
  "** Enter the part number with no dashes-, slashes /, or other marks.Use Letters and Numbers only.";
export const SEARCH_FILTER_HEADER = "Filter Search";
export const PARTIAL_FILTER_HEADER = "Filter Partial Search";
export const SEARCH_LABEL = "Search";
export const QUICK_SEARCH_FILTER_HEADER = "Quick Search";
export const MAKE_LABEL = "Make";
export const MODEL_LABEL = "Model";
export const YEAR_LABEL = "Year";
export const YEAR_MSG = "** 4 digit Year";
export const FILTER_SEARCH_HEADER = "FILTER SEARCH";
export const APPLICATION_LOOKUP = "Application LookUp";
export const FILTER_SEARCH = "Filter Search";
export const QUICK_SEARCH = "Quick Search";
export const FILTER_BY_SEARCH = "Look Up Filter By Size/Dimensions";
export const COMPETITOR_INTERCHANGE = "Competitor Interchange";
export const COMPETITOR_PARTIAL_LOOKUP = "Competitor Partial Number Look Up";
export const LOOKUP_BY_DIMENSION = "Look Up Filter By Size/Dimensions";
export const PARTIAL_SEARCH_MSG =
  "**Don't know the full number? \n Try our partial number search:";
export const COMPETITOR_INTERCHANGE_MSG =
  "**Enter the part number with no dashes-, slashes /, or other marks.Use Letters and Numbers only.";
export const SHOP_ONLINE_BTN = "Shop@NAPAOnline";
export const SHOP_PROLINK_BTN = "Shop@ProLink";
export const PART_SEARCH_REF_TEXT =
  "For reference only. There are no express or implied warranties with respect to products selected by size, feature or invalid cross reference. Warranties only apply to products selected according to vehicle application listing. For non-listed applications, warranty coverage requires a valid OEM cross reference as described in the NAPA Filters Limited Warranty. No product has been certified or warrantied for aviation use. No product has been certified or warrantied for filtration of fuel or oil in heating applications.";
export const DISCLAIMER_TEXT =
  "Disclaimer: For reference only. There are no express or implied warranties with respect to products selected by size, feature or invalid cross reference. Warranties only apply to products selected according to vehicle application listing. For non-listed applications, warranty coverage requires a valid OEM cross reference as described in the NAPA Filters Limited Warranty. No product has been certified or warrantied for aviation use. No product has been certified or warrantied for filtration of fuel or oil in heating applications.";
export const CLOSE_LABEL = "Close";
export const PRINT_LABEL = "Print";
export const CLICK_AND_SELECT_LABEL =
  "Choose one record and click Select to continue.";
export const TECH_SEC_HEADING = "Technical Bulletins for Part Number";
export const PROP_65_HEADING =
  " California Proposition 65 Warning for Part Number";
export const PROP_TAB = "Prop 65";
export const VIDEO_TAB = "Videos";
export const IMAGE_TAB = "Images";
export const INSTRU_TAB = "Instructions";
export const OIL_FILTER = "Oil Filter";
export const FUEL_FILTER = "Fuel Filter";
export const AIR_FILTER = "Air Filter";
export const CABIN_AIR_FILTER = "Cabin Air Filter";
export const TRANSMISSION_FILTER = "Transmission Filter";
export const BREATHER_FILTER = "Breather Filter";
export const HYDRAULIC_FILTER = "Hydraulic Filter";
export const APP_TITLE1 = "NAPA Filters Look-up";
export const APP_SUBTITLE = "  Find the proper NAPA filter for any vehicle";
export const TECH_BULLETINS_TAB = "Technical Bulletins";
export const PART_DETAILS_MODAL_HEADING = "Part Applications List";
export const ALL_APPLICATIONS_LIST_TITLE = "Applications for Part";
export const PRODUCT_SPEC_TITLE = "Product Specifications";
export const GASKET_DIA_TITLE = "Gasket Diameters";
export const PRINCIPAL_APP_TEXT = "Principle Application";
export const PRINCIPAL_APP_SUBTEXT =
  "Various Industrial Hydraulic Applications";
export const APPL_LINK_TEXT = "All Applications";
export const ERROR_MSG = "Please enter at least one part number";
export const ERR_MSG_MODAL_NAME = "Please enter a Model name";
export const ERR_MSG_PART_NO = "Please enter part number";
export const ERR_MSG_VIN_NUM = "Please enter VIN number";
export const ERR_MSG_VIN_NUM_INVALID = "Please enter valid VIN number"
export const leadModalHeading = "1ST TO MARKET - NEW PRODUCT LEADER";
export const leadModalsubHeading =
  "Be ahead. Check out these must-stock new products from the 1st to Market Leader.";
export const REFERENCE_TEXT =
  "For reference only. There are no express or implied warranties with respect to products selected by size, feature or invalid cross reference. Warranties only apply to products selected according to vehicle application listing. For non-listed applications, warranty coverage requires a valid OEM cross reference as described in the NAPA Filters Limited Warranty. No product has been certified or warrantied for aviation use. No product has been certified or warrantied for filtration of fuel or oil in heating applications";
export const NO_ITEM_FOUND_TEXT = "No item found.";
export const SOMETHING_WENT_WRONG_TEXT = "Something went wrong";
export const NO_DATA_FOUND_TEXT = "No data found.";
export const MAIN_HEAD_TABS = ["Home", "Product Comparison"];
export const SUPPRESSED_WARNINGS = [
  "Each child in a list",
  "A component is changing an",
  "validateDOMNesting",
  "Failed prop type",
  "Failed prop types: Invalid prop `className`",
  "key",
  "non-boolean attribute"
];
export const VEHICLE_TYPE = "Vehicle Type";
export const MODEL_YEAR = "Model Year";
export const TYPE = "Type";
export const MAKE = "Make";
export const MODEL = "Model";
export const ENGINE = "Engine";
export const SUB_MODEL = "Submodel";
export const APPLICATION_LOOKUP_TYPE = "applicationLookUp";
export const COMPETITOR_INTERCHANGE_TYPE = "competitorInterchange";
export const FILTER_BY_SIZE_TYPE = "filterbysize";
export const PARTIAL_SEARCH_TYPE = "partialSearch";
export const FILTER_SEARCH_TYPE = "filterSearch";
export const VIN_SEARCH_TYPE = "VinSearch";
export const DYNAMIC_SEARCH_TYPE = "dynamicSearch";
export const NAPA_PARTNUMBER = "napaPartNumber";
export const SEARCH_NUMBER = "searchNumber";
export const CARTRIDGE_STYLE = "Cartridge";
export const CONE_STYLE = "Cone";
export const CYLINDRICAL_STYLE = "Cylindrical";
export const ROUND_STYLE = "Round";
export const ROUND_SEAL_STYLE = "Round Seal";
export const ROUND_PANEL_STYLE = "Round Panel";
export const PANEL_STYLE = "Panel";
export const OIL = "Oil";
export const SPINON_STYLE = "Spin-On";
export const CANISTER_STYLE = "Canister";
export const INLINE_STYLE = "In-Line";
export const QUALIFIER = "qualifier";
export const PARTNUMBER = "partNumber";
export const FUEL = "Fuel";
export const AIR = "Air";
export const CAIR = "CAir";
export const TRANS = "Trans";
export const BREATHER = "Breather";
export const HYDRAULIC = "Hydraulic";
export const OTHERS = "Others";
export const FIELDSKU = "fieldSku";
export const YEAR_MAKE_MODEL = "yearMakeModel";
export const UNKNOWN_VALUE = "Unknown";
export const APPLICATION = "application";
export const THREAD_SIZE = "Thread Size";
export const THREAD_SIZE_VALUE = "threadSize";
export const FILTERLOOKUP_TITLE = "Filter Lookup By Size/Dimension";
export const ALL_APPLICATIONS_LIST_TYPE = "allpartallpicationlist";
export const COMP_INTERCHANGE = "competitorsinterchangelist";
export const GASKET_DIAMETER_TYPE = "gasketdiameter";
export const PART_NUMBER = "Part Number";
export const SELECET_DIMENSION_TXT = "Select Dimension";
export const SPEC_HEIGHT = "spec_height";
export const SPEC_LENGTH = "spec_length";
export const PDF_CATALOGS = "PDF Catalogs";
export const POWER_SPORTS = "power sports";
export const NAPA_POWERSPORTS_TITLE = "NAPA Powersports Catalog (Coming Soon)";
export const PARTIAL = "partial";
export const PARTNUM_SEARCH = "partNumberSearch";
export const COMPETITOR_SEARCH_RESULT =
  "COMPETITOR INTERCHANGE SEARCH RESULT FOR:";
export const FILTER_SEARCH_RESULT = "FILTER SEARCH RESULT FOR:";
export const VIN_SEARCH_RESULT = "VIN SEARCH RESULT FOR:";
export const DYNAMIC_SEARCH_RESULT = "DYNAMIC SEARCH RESULT FOR:";
export const COMPETITOR_PARTIAL_SEARCH =
  "COMPETITOR PARTIAL NUMBER SEARCH RESULT FOR:";
export const SEARCH_RESULT_TEXT = "SEARCH RESULT FOR:";
export const FILTER_ABBR = [
  "SFI",
  "HFI",
  "FIL",
  "DCI",
  "BSH",
  "HEF",
  "AC",
  "MAF",
];
export const STOCK_LABEL = "STOCK";
export const PRIMARY_APPL = "Primary Applications";
export const LISTENING_TEXT = "Listening...";
export const ERR_MSG_PART_NUMBER = "please enter valid part number(s)";
export const MICROPHONE_NOT_SUPPORTED =
  "Browser is not Support Speech Recognition.";
export const DYNAMIC_SEARCH_HEAD = "Dynamic Part Number Search";
export const SEARCH_BY_TEXT = "Search by";
export const VIN_NUMBER_HEAD = "VIN Number Search";
export const COMPARISON_PART_POPOVER =
  "Select an available part number from the dropdown menu.";
export const YEAR_VALIDATION = "From year is greater than To year";
export const COMPARE_MSG = "Select up to 4 products only for comparison";
export const PRODUCT_COMP_HEADING = "PRODUCT COMPARISON";
export const PRODUCT_COMPARE_MSG1 = `NAPA's innovative filter comparison tool allows users to compare filter specifications for added peace of mind.`;
export const POPOVER_MSG1 =
  "Select Vehicle Type, Make, year range and then choose model to see what products are available.";
//style constants
export const APPBAR_STYLES = {
  height: "70px !important",
  backgroundColor: "#fff",
  color: "#000",
};
export const MT_10 = { marginTop: "10px" };
export const MT_40 = { marginTop: "40px" };
export const MT_16 = { marginTop: "16px" };
export const MT_15 = { marginTop: "15px" };
export const MT_1 = { marginTop: "1%" };
export const MT_6 = { marginTop: "6px" };
export const MT_5 = { marginTop: "5px" };
export const MT_MINUS_10 = { marginTop: "-10px" };
export const ML_30 = { marginTop: "30px" };
export const MB_10 = { marginBottom: "10px" };
export const MB_12 = { marginBottom: "12px" };
export const MB_0 = { marginBottom: "0px" };
export const MB_20 = { marginBottom: "20px" };
export const MR_10 = { marginRight: "10px" };
export const MR_2 = { marginRight: "2%" };
export const MR_35 = { marginRight: "35px" };
export const PT_10 = { paddingTop: "10px" };
export const COLOR_RED = { color: "red" };
export const COLOR_BLACK = { color: "black" };
export const COLOR_DARKBLUE = { color: "darkblue" };
export const TXT_CENTER = { textAlign: "center" };
export const TEXT_MD = { fontSize: "0.875rem !important" };
export const FONTSIZE = { fontSize: "1rem" };
export const FONT700 = { fontWeight: "700" };
export const FONT400 = { fontWeight: "400" };
export const M8 = { margin: "10px" };
export const M2 = { margin: "2px" };
export const FONT_NORMAL = { fontWeight: "normal" };
export const FONT500 = { fontWeight: "500" };
export const P5 = { padding: "5px" };
export const PT1 = { paddingTop: "1%" };
export const FLOAT_RIGHT = { float: "right", marginTop: "10px !important" };
export const DISPLAY_FLEX_END = { display: "flex", justifyContent: "flex-end" };
export const DISPLAY_FLEX_CENTER = {
  display: "flex",
  justifyContent: "center",
};
export const COLOR_GREEN = { color: "green" };
export const cursorPointerRelPos = { cursor: "pointer", position: "relative" };
export const ShopOnlineBtnStyle = { width: "auto", flex: 1 / 2 };
export const LIST_ITEM_TEXT = { wordBreak: "normal", textAlign: "left" };
export const WIDTH_40 = { width: "40%" };
export const WIDTH_100 = { width: "100%" };
export const WIDTH_100_px = { width: "100px" };
export const MAX_WIDTH_100 = { maxWidth: "100%" };
export const MIN_WIDTH_100 = { minWidth: "100%" };
export const MIN_WIDTH_750 = { minWidth: 750 };
export const WIDTH_70 = { width: "70%" };
export const WIDTH_45 = { width: "45%" };
export const WIDTH_15 = { width: "15%" };
export const WIDTH_30 = { width: "30%" };
export const HEIGHT_45 = { height: "45px" };
export const TRANSFORM_NONE = { textTransform: "none" };
export const BORDER_TOP_3REMBLACK = { borderTop: ".3rem solid #ddd" };
export const BORDER_BTM_BLACK = { borderBottom: "1px solid #ddd" };
export const BORDER_BOTTOM_NONE = { borderBottom: "none" };
export const BOX_BORDER = {
  p: 1,
  border: "1px solid #e5e5e5",
  borderRadius: "5px",
};
export const DISPLAY_IMG_XS = { display: { md: "none", xs: "flex" } };
export const DISPLAY_IMG_MD = { display: { md: "flex", xs: "none" } };
export const DISPLAY_MENU = { display: { xs: "block", md: "none" } };
export const FLEX_GROW_1 = { flexGrow: 1 };
export const COLOR_RGB_37_99_235 = { color: "rgb(37,99,235, 1)" };
export const COLOR_000 = { color: "#000" };
export const COLOR_FFF = { color: "#fff" };
export const BGCOLOR_FFF = { backgroundColor: "#fff" };
export const BGCOLOR_EFF6FF = { backgroundColor: "#EFF6FF" };
export const FONT_SIZE_125REM = { fontSize: "1.20rem" };
export const FONT_SIZE_1REM = { fontSize: "1rem" };
export const FONT_SIZE_115REM = { fontSize: "1.15rem" };
export const LINE_HGT_175 = { lineHeight: "1.75rem" };
export const FONT_SIZE_0875REM = { fontSize: "0.875rem" };
export const LINE_HGT_125 = { lineHeight: "1.25rem" };
export const DATALIST_CELL_STYLE = {
  fontWeight: "300",
  paddingLeft: "12%",
  textAlign: "justify",
  width: "50%",
};
export const DATATABLE_CELL_STYLE = {
  height: "47px",
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
};
export const DATATABLE_HEAD_STYLE = {
  fontWeight: 700,
  paddingLeft: "19px !important",
};
export const partTableStyles = {
  position: "absolute",
  backgroundColor: "white",
  padding: "10px",
  boxShadow: "6px 6px 9px #b6bfbf, -6px -6px 9px #edfbfafa",
  zIndex: 10,
  width: "90px",
  fontSize: "16px",
};
export const ACCORDION_SUMMARY = {
  textDecoration: "underline",
  fontWeight: "bold",
  backgroundColor: "rgb(239 246 255)",
  borderRadius: "1px solid #ccc",
};
export const ACCORDION_Details = {
  overflow: "auto",
  maxHeight: "200px",
  maxWidth: "100%",
};
export const dimesionTableStyle = {
  cursor: "pointer",
  padding: "4px 18.1px !important",
  margin: "2.1px",
  maxWidth: "100px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:hover": {
    backgroundColor: "rgb(243 244 246) ",
  },
};
export const partDetailsRightPanel = {
  flexGrow: 1,
  display: { xs: "flex", md: "none" },
  border: "1px solid #dfe1e5",
  borderRadius: "5px",
  color: "#000",
  padding: "7px 9px",
};
export const leftPanelTableHeading = {
  backgroundColor: "rgb(239 246 255) !important",
  display: "flex",
  alignItems: "center",
};
export const LIST_ITEM = {
  padding: "0px !important",
  display: "flex",
  flexDirection: "row",
  paddingLeft: "10px !important",
};
export const leftPanelTableWrapper = {
  border: "1px solid #dfe1e5",
  borderRadius: "5px",
  textAlign: "left",
};
export const TEXT_LG = {
  fontSize: "1.25rem !important",
  fontWeight: "500 !important",
};
export const NAVBAR_CONTAINER = {
  paddingLeft: "5% !important",
  paddingRight: "5% !important",
  maxWidth: "100% !important",
};
export const NavBtn = {
  fontWeight: 600,
  lineHeight: 1.5,
  color: "rgb(30, 65, 72)",
  fontSize: "14px",
  borderRadius: "0.25rem",
  backgroundColor: "transparent",
  textDecoration: "none",
  border: "1px solid rgb(222, 228, 228)",
  textAlign: "center",
  cursor: "pointer",
  padding: "0.5rem 0.75rem",
};
export const NavBtnPos = {
  flexGrow: 1,
  justifyContent: "flex-end",
  display: { xs: "none", md: "flex" },
};
export const DataListItemText = {
  fontWeight: "700",
  width: "8rem",
  wordBreak: "normal",
  float: "left",
};
export const DataTableCellStyle = {
  height: "47px",
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
};
export const DataTableCellPadding = {
  paddingLeft: "19px !important",
};
export const POS_AB_RT_8 = {
  position: "absolute",
  right: 8,
  top: 8,
};
export const MARGIN_PADD_02 = {
  m: 0,
  p: 1,
  borderBottom: "1px lightgray solid",
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "700",
};

export const SHOP_BTN_STYLE = {
  fontWeight: "300",
  paddingLeft: "12%",
  textAlign: "justify",
  width: "50%",
};
export const SHOP_BTN_LIST_ITEM = {
  fontWeight: "700",
  width: "8rem",
  wordBreak: "normal",
  float: "left",
};
export const ROW_STYLE = {
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
};
export const BTN_STYLE = { width: "50%", margin: "2px", marginRight: "8px" };
export const BTN_STYLE_NOL = { width: "50%", margin: "2px" };
export const HOME_MAIN_STYLE = {
  flexGrow: 1,
  paddingLeft: "5%",
  paddingRight: "5%",
};
export const DYNAMIC_LAYER = {
  position: "absolute",
  zIndex: 1000,
  color: "black",
  background: "white",
  height: "auto",
  maxHeight: "300px",
  width: "95%",
  top: 100,
  border: "1px solid lightgray",
  borderRadius: 5,
  padding: "1rem",
  overflowY: "scroll",
};
export const HOME_GRID_STYLE = { marginBottom: "20px", marginTop: "-10px" };
export const POPOVER_STYLE = {
  pointerEvents: "none",
  alignItems: "center",
  textAlign: "center",
  marginLeft: "2%",
  fontWeight: 400,
  fontSize: "14px",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
};
export const LATEMODEL_CARD_CONTAINER = {
  display: "flex",
  flexDirection: "row",
};
export const LATEMODEL_CARD_IMG = {
  width: { xs: "120px", md: "190px" },
  height: "auto",
  objectFit: "contain",
  borderRadius: "8px 0 0 8px",
  maxWidth: "100%",
};
export const LATEMODEL_PARTTYPE = {
  marginBottom: { xs: "10px", md: "5px" },
  letterSpacing: "0.01em",
  fontSize: { xs: "0.8rem", md: "1rem" },
  fontWeight: "400 !Important",
};
export const LATEMODEL_TYPE = {
  marginLeft: { xs: "0px", md: "80px" },
  letterSpacing: "0.01em",
  fontSize: { xs: "0.8rem", md: "1rem" },
  fontWeight: "400 !Important",
};
export const PRIMARYAPPLICATION = {
  marginBottom: "20px",
  fontSize: { xs: "0.8rem", md: "1rem" },
  letterSpacing: "0.02em",
  fontWeight: "400 !Important",
};
export const LATEMODEL_PART_TYPE_CONTAINER = {
  marginBottom: "10px",
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  justifyContent: "flex-start",
};
export const ACC_TABLE_ROW = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};
export const ROW_FLEX = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};
export const MAIL_ASSET_STYLE = {
  height: "100px",
  width: "100px",
  cursor: "pointer",
  marginRight: "20px",
};
export const COMP_SCROLL = { height: "380px", overflowY: "auto" };
export const FLEX_DIRECTION_ROW = { flexDirection: "row" };
export const CURSOR_POINTER = { cursor: "pointer" };
export const DYNAMIC_ITEM_STYLE = {
  fontWeight: 400,
  fontSize: "14px",
  fontFamily:
    "ui-sans-serif, system-ui, sans-serif, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;",
};
export const DYNAMIC_POPUP = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const POS_RELATIVE = { position: "relative" };
export const HEIGHT_80 = { height: "80% !important" };
export const FEEDBACK_BTN_STYLE = {
  marginTop: "40px",
  width: "100px",
  position: "fixed",
  zIndex: "1",
  right: "-80px",
  transform: "translateX(0%) translateY(-280%) rotate(90deg)",
  transformOrigin: "left",
  bottom: "50%",
};
