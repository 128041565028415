// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-row {
  display: flex;
  justify-content: space-between;
}

.filter-container {
  display: flex;
  justify-content: space-between;
}

.header-desk {
  text-align: -webkit-center;
  position: absolute;
  top: 4%;
  left: 11%;
  right: 20%;
}

.header-mob {
  text-align: -webkit-center;
  margin: 10px 8%;
}

.filter-column {
  flex: 1 1;
}

.filter-field {
  display: flex;
  flex-direction: column;
}

.date-picker {
  width: 80%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 9999;
}

.grid-align {
  margin: 0% 8%;
}

.left-grid {
  margin: 0% -20% 0% 20%;
}

.fieldset-style-main :hover {
  transform: none;
}

.fieldset-style-hide :hover {
  transform: none;
}

/*Tablet*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .grid-align {
    margin: 0%;
  }

  .left-grid {
    margin: 0%;
  }
}

/*Mobile*/
@media only screen and (max-width: 640px) {
  .grid-align {
    margin: 0%;
  }

  .left-grid {
    margin: 0%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/productComparison/ProductComparison.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA,SAAS;AACT;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF;;AAEA,SAAS;AACT;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".filter-row {\n  display: flex;\n  justify-content: space-between;\n}\n\n.filter-container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.header-desk {\n  text-align: -webkit-center;\n  position: absolute;\n  top: 4%;\n  left: 11%;\n  right: 20%;\n}\n\n.header-mob {\n  text-align: -webkit-center;\n  margin: 10px 8%;\n}\n\n.filter-column {\n  flex: 1;\n}\n\n.filter-field {\n  display: flex;\n  flex-direction: column;\n}\n\n.date-picker {\n  width: 80%;\n  padding: 10px;\n  border-radius: 4px;\n  border: 1px solid #ccc;\n  background: #fff;\n  z-index: 9999;\n}\n\n.grid-align {\n  margin: 0% 8%;\n}\n\n.left-grid {\n  margin: 0% -20% 0% 20%;\n}\n\n.fieldset-style-main :hover {\n  transform: none;\n}\n\n.fieldset-style-hide :hover {\n  transform: none;\n}\n\n/*Tablet*/\n@media only screen and (min-width: 768px) and (max-width: 1023px) {\n  .grid-align {\n    margin: 0%;\n  }\n\n  .left-grid {\n    margin: 0%;\n  }\n}\n\n/*Mobile*/\n@media only screen and (max-width: 640px) {\n  .grid-align {\n    margin: 0%;\n  }\n\n  .left-grid {\n    margin: 0%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
