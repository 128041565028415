/* eslint-disable  react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import styled from "@emotion/styled/macro";
import LinkView from "../link/LinkView";
import DOMPurify from "dompurify";
import {
  AIR,
  ALL_APPLICATIONS_LIST_TYPE,
  APPLICATION_LOOKUP_TYPE,
  BREATHER,
  BTN_STYLE,
  BTN_STYLE_NOL,
  CAIR,
  CANISTER_STYLE,
  CARTRIDGE_STYLE,
  CONE_STYLE,
  CYLINDRICAL_STYLE,
  DATATABLE_CELL_STYLE,
  FIELDSKU,
  FILTER_BY_SIZE_TYPE,
  FILTER_SEARCH_TYPE,
  FONT_SIZE_0875REM,
  FUEL,
  GASKET_DIAMETER_TYPE,
  HYDRAULIC,
  INLINE_STYLE,
  MIN_WIDTH_100,
  MIN_WIDTH_750,
  NAPA_PARTNUMBER,
  NO_DATA_FOUND_TEXT,
  OIL,
  OTHERS,
  PANEL_STYLE,
  PART_NUMBER,
  PARTIAL_SEARCH_TYPE,
  PARTNUMBER,
  POPOVER_STYLE,
  ROUND_PANEL_STYLE,
  ROUND_SEAL_STYLE,
  ROUND_STYLE,
  ROW_STYLE,
  SHOP_ONLINE_BTN,
  SHOP_PROLINK_BTN,
  SPINON_STYLE,
  THREAD_SIZE,
  THREAD_SIZE_VALUE,
  TRANS,
  UNKNOWN_VALUE,
  WIDTH_100,
  FILTER_SEARCH,
  FILTER_ABBR,
  VIN_SEARCH_TYPE,
  DYNAMIC_SEARCH_TYPE,
  STOCK_LABEL,
  COMP_INTERCHANGE,
} from "../../../utils/constants/GlobalConstants";
import "./DataTable.css";
import ButtonLayout from "../button/ButtonLayout";
import { getRequest } from "../../../services/RequestConfig";
import SimpleSnackbar from "../snackBar/SnackBar";
import {
  APP_LOOKUP_HEADERS,
  FILTER_BY_SIZE_HEADERS,
  FILTER_SEARCH_HEADERS,
  GASKET_DIAMETER_HEADERS,
  PARTIAL_SEARCH_HEADERS,
  VIN_SEARCH_HEADERS,
  PART_APP_HEADERS,
  FILTER_BY_SIZE_HEADERS_INLINE,
  FILTER_BY_SIZE_HEADERS_CATRIDGE,
  FILTER_BY_SIZE_HEADERS_SPINON,
  FILTER_BY_SIZE_HEADERS_PANEL,
  FILTER_BY_SIZE_HEADERS_ROUND,
  NAPA_PROLINK_URL,
  NAPA_ONLINE_URL,
  TRACKER,
  NAPA_MEDIA_URL,
  COMP_INTERCHANGE_LIST_HEADERS,
} from "../../../utils/constants/GlobalFormConstants";
import { Popover } from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(239 246 255)",
    paddingLeft: 10,
  },
  "&:hover": {},
  [`&.${tableCellClasses.body}`]: {
    paddingLeft: 10,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgb(239 246 255)",
    height: "35px !important",
  },
  "&:hover": {
    backgroundColor: "rgb(243 244 246) !important",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd)": {
    height: "35px !important",
  },
}));

function createApplicationLookUpData(
  MakeModel,
  Qualifier,
  Oil,
  Fuel,
  Air,
  CAir,
  Trans,
  Breather,
  Hydraulic,
  Others
) {
  return {
    MakeModel,
    Qualifier,
    Oil,
    Fuel,
    Air,
    CAir,
    Trans,
    Breather,
    Hydraulic,
    Others,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell, index) => (
          <StyledTableCell
            sx={{ ...DATATABLE_CELL_STYLE }}
            key={index}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

const DataTable = React.memo((props) => {
  const dense = true;
  const noOfPages = [5, 10, 15, 20, 25, 30];
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [fullData, setFullData] = React.useState([]);
  const [apiError, setApiError] = React.useState({ val: false, messages: "" });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [imgURL, setImgURL] = React.useState();
  const [partNum, setPartNum] = React.useState();

  const onPressLink = async (prtNum, index) => {
    let productDetails;
    try {
      if (props.source === APPLICATION_LOOKUP_TYPE) {
        productDetails = await getRequest(
          `filters/specifications?productIdPartNumber=${prtNum?.productIdPartNumber}`
        );
      } else {
        const sortedPartnum = fullData.filter((item) =>
          props.source === FILTER_BY_SIZE_TYPE
            ? item.partNumber === partNum
            : item.napaPartNumber === prtNum
        );
        productDetails = await getRequest(
          `filters/specifications?productIdPartNumber=${sortedPartnum[0]?.productIdPartNumber}`
        );
      }
      props.onPressLink(productDetails?.data);
    } catch (err) {
      setApiError((val) => ({ ...val, val: true, message: err.message }));
    }
  };

  const sortFilters = (type) => {
    const sortedList =
      type.length > 0 &&
      type.sort((a, b) =>
        a.partNumbers.toUpperCase() > b.partNumbers.toUpperCase() ? 1 : -1
      );
    return sortedList ? sortedList : [];
  };
  React.useEffect(() => {
    if (props.source === FILTER_BY_SIZE_TYPE) {
      setOrderBy("partNumber");
    } else if (
      props.source === FILTER_SEARCH_TYPE
    ) {
      setOrderBy("napaPartNumber");
    } else if (props.source === PARTIAL_SEARCH_TYPE) {
      setOrderBy("partialPartNumber");
    } else {
      setOrderBy("");
    }
  }, [props.source]);

  const getApplicationLookupData = () => {
    let temp = [];
    props?.data &&
      props?.data.forEach((item) => {
        temp.push(
          createApplicationLookUpData(
            item.makeModel,
            item.qualifier,
            sortFilters(item.oil),
            sortFilters(item.fuel),
            sortFilters(item.air),
            sortFilters(item.cAir),
            sortFilters(item.trans),
            sortFilters(item.breather),
            sortFilters(item.hydraulic),
            sortFilters(item.others)
          )
        );
      });
    setRows(temp);
  };

  const getFilterBySizeData = () => {
    let temp = [];
    props?.dimensionData &&
    (props?.SelectedStyle === CARTRIDGE_STYLE ||
      props?.SelectedStyle === CONE_STYLE ||
      props?.SelectedStyle === CYLINDRICAL_STYLE ||
      props?.SelectedStyle === ROUND_STYLE ||
      props?.SelectedStyle === ROUND_SEAL_STYLE ||
      props?.SelectedStyle === ROUND_PANEL_STYLE)
      ? props?.dimensionData.forEach((item) => {
          temp.push({
            partNumber: item.partNumber,
            height: item.height,
            bottomInsideDiameter: item.bottomInsideDiameter,
            bottomOutsideDiameter: item.bottomOutsideDiameter,
            topOutsideDiameter: item.topOutsideDiameter,
            topInsideDiameter: item.topInsideDiameter,
          });
        })
      : props?.dimensionData && props?.SelectedStyle === PANEL_STYLE
      ? props?.dimensionData.forEach((item) => {
          temp.push({
            partNumber: item.partNumber,
            height: item.height,
            length: item.length,
            width: item.width,
          });
        })
      : (props?.dimensionData && props?.SelectedStyle === SPINON_STYLE) ||
        (props?.dimensionData && props?.SelectedStyle === CANISTER_STYLE)
      ? props?.dimensionData.forEach((item) => {
          temp.push({
            partNumber: item.partNumber,
            height: item.height,
            threadSize: item.threadSize,
            topOutsideDiameter: item.topOutsideDiameter,
            gasketIncluded: item.gasketIncluded,
            gasketInsideDiameter: item.gasketInsideDiameter,
            gasketOutletDiameter: item.gasketOutletDiameter,
          });
        })
      : props?.dimensionData && props?.SelectedStyle === INLINE_STYLE
      ? props?.dimensionData.forEach((item) => {
          temp.push({
            partNumber: item.partNumber,
            height: item.height,
            topOutsideDiameter: item.topOutsideDiameter,
            inlet: item.inlet,
            outlet: item.outlet,
          });
        })
      : props?.dimensionData &&
        props?.dimensionData.forEach((item) => {
          temp.push({
            partNumber: item.partNumber,
            height: item.height,
            length: item.length,
            threadSize: item.threadSize,
            width: item.width,
            bottomInsideDiameter: item.bottomInsideDiameter,
            bottomOutsideDiameter: item.bottomOutsideDiameter,
            topOutsideDiameter: item.topOutsideDiameter,
            topInsideDiameter: item.topInsideDiameter,
            gasketIncluded: item.gasketIncluded,
            gasketInsideDiameter: item.gasketInsideDiameter,
            gasketOutletDiameter: item.gasketOutletDiameter,
          });
        });
    setRows(temp);
  };
  React.useEffect(() => {
    let filteredAbbr = FILTER_ABBR;
    if (props.source === FILTER_BY_SIZE_TYPE) {
      setFullData(props?.dimensionData);
    }

    if (
      props?.source === FILTER_SEARCH_TYPE ||
      props?.source === DYNAMIC_SEARCH_TYPE
    ) {
      let filterAbbrData = props?.filterSearchRes?.filter((item) =>
        filteredAbbr?.includes(item?.abbreviation)
      );
      setFullData(filterAbbrData);
      let filteredData = filterAbbrData?.map(
        ({
          partNumber,
          manufacturer,
          abbreviation,
          napaPartNumber,
          fieldSku,
          leadTime,
        }) => ({
          partNumber,
          manufacturer,
          abbreviation,
          napaPartNumber,
          fieldSku,
          leadTime: STOCK_LABEL,
        })
      );
      setRows(filteredData);
    } else if (props?.source === PARTIAL_SEARCH_TYPE) {
      let filterAbbrData = props?.filterSearchRes?.filter((item) =>
        filteredAbbr?.includes(item?.abbreviation)
      );
      setFullData(filterAbbrData);
      let filteredData = filterAbbrData?.map(
        ({
          partialPartNumber,
          manufacturer,
          abbreviation,
          napaPartNumber,
          leadTime,
        }) => ({
          partialPartNumber,
          manufacturer,
          abbreviation,
          napaPartNumber,
          leadTime: STOCK_LABEL,
        })
      );
      setRows(filteredData);
    } else if (props?.source === ALL_APPLICATIONS_LIST_TYPE) {
      const modifiedData = props?.filterSearchRes?.flatMap((car) =>
        car?.engines !== null
          ? car?.engines?.map((engine) => ({
              make: car.make,
              model: car.model,
              startYear: car.startYear,
              endYear: car.endYear,
              engine: engine,
            }))
          : car && {
              make: car.make,
              model: car.model,
              startYear: car.startYear,
              endYear: car.endYear,
              engine: null,
            }
      );
      let filteredData = modifiedData?.map(({ make, model, engine }) => ({
        make,
        model,
        engine,
      }));
      setRows(filteredData);
    } else if (props?.source === COMP_INTERCHANGE) {
      let CompInterchangeData = props?.filterSearchRes?.map(
        ({ brand, partNum }) => ({
          brand,
          partNum,
        })
      );
      setRows(CompInterchangeData);
    } else if (props.source === APPLICATION_LOOKUP_TYPE) {
      getApplicationLookupData();
    } else if (props.source === FILTER_BY_SIZE_TYPE) {
      getFilterBySizeData();
    } else if (props.source === GASKET_DIAMETER_TYPE) {
      let sampleArr =
        props?.data?.map(
          ({
            gasketOutletDiameter,
            gasketInsideDiameter,
            gasketThickness,
          }) => ({
            gasketOutletDiameter,
            gasketInsideDiameter,
            gasketThickness,
          })
        ) || [];
      if (
        sampleArr.length > 0 &&
        ((sampleArr[0]?.gasketInsideDiameter === UNKNOWN_VALUE &&
          sampleArr[0]?.gasketOutletDiameter === UNKNOWN_VALUE &&
          sampleArr[0]?.gasketThickness === UNKNOWN_VALUE) ||
          (sampleArr[0]?.gasketInsideDiameter === "" &&
            sampleArr[0]?.gasketOutletDiameter === "" &&
            sampleArr[0]?.gasketThickness === ""))
      ) {
        setRows([]);
      } else {
        setRows(sampleArr);
      }
    } else if (props?.source === VIN_SEARCH_TYPE) {
      let filterAbbrData = props?.filterSearchRes?.filter((item) =>
        filteredAbbr?.includes(item?.abbreviation)
      );
      setFullData(filterAbbrData);
      let sortedOdrOil = filterAbbrData?.filter((itm) =>
        itm?.productDescription?.toUpperCase()?.includes("OIL")
      );
      let sortedOdrAir = filterAbbrData?.filter((itm) =>
        itm?.productDescription?.toUpperCase()?.includes("AIR")
      );
      let sortedOdrFuel = filterAbbrData?.filter((itm) =>
        itm?.productDescription?.toUpperCase()?.includes("FUEL")
      );
      let leftFilters = filterAbbrData?.filter(
        (itm) =>
          !itm?.productDescription?.toUpperCase()?.includes("OIL") &&
          !itm?.productDescription?.toUpperCase()?.includes("AIR") &&
          !itm?.productDescription?.toUpperCase()?.includes("FUEL")
      );
      let finalData;
      if (filterAbbrData) {
        finalData = [
          ...sortedOdrOil,
          ...sortedOdrAir,
          ...sortedOdrFuel,
          ...leftFilters,
        ];
      }

      let filteredData = finalData?.map(
        ({
          productDescription,
          napaPartNumber,
          fieldSku,
          imageValue,
          fitmentNote,
        }) => ({
          productDescription,
          napaPartNumber,
          fieldSku,
          imageValue,
          fitmentNote,
        })
      );
      setRows(filteredData);
    }
    setPage(0);
    setRowsPerPage(5);
  }, [
    props?.source,
    props?.inputPartNum,
    props?.dimensionData,
    props.data,
    props?.filterSearchRes,
  ]);

  const setupDimensionHeaders = (style) => {
    const headerStyle =
      style === CARTRIDGE_STYLE ||
      style === CONE_STYLE ||
      style === CYLINDRICAL_STYLE
        ? FILTER_BY_SIZE_HEADERS_CATRIDGE
        : style === ROUND_STYLE ||
          style === ROUND_SEAL_STYLE ||
          style === ROUND_PANEL_STYLE
        ? FILTER_BY_SIZE_HEADERS_ROUND
        : style === PANEL_STYLE
        ? FILTER_BY_SIZE_HEADERS_PANEL
        : style === SPINON_STYLE || style === CANISTER_STYLE
        ? FILTER_BY_SIZE_HEADERS_SPINON
        : style === INLINE_STYLE
        ? FILTER_BY_SIZE_HEADERS_INLINE
        : FILTER_BY_SIZE_HEADERS;
    return headerStyle;
  };

  const headCells =
    props.source === FILTER_SEARCH_TYPE
      ? FILTER_SEARCH_HEADERS
      : props.source === VIN_SEARCH_TYPE
      ? VIN_SEARCH_HEADERS
      : props.source === ALL_APPLICATIONS_LIST_TYPE
      ? PART_APP_HEADERS
      : props.source === COMP_INTERCHANGE
      ? COMP_INTERCHANGE_LIST_HEADERS
      : props.source === GASKET_DIAMETER_TYPE
      ? GASKET_DIAMETER_HEADERS
      : props.source === APPLICATION_LOOKUP_TYPE
      ? APP_LOOKUP_HEADERS
      : props.source === FILTER_BY_SIZE_TYPE
      ? setupDimensionHeaders(props.SelectedStyle)
      : PARTIAL_SEARCH_HEADERS;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handlePopoverOpen = (event, index) => {
    let partNum = event.target.innerText.trim();
    let imageDetails = fullData?.filter((item) => item.partNumber === partNum);
    let img = imageDetails[0];
    let url = `${NAPA_MEDIA_URL}${img?.thumbImage}`;
    setPartNum(partNum);
    setImgURL(url);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      rows &&
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <>
      <Box sx={{ ...WIDTH_100 }}>
        <Popover
          id="mouse-over-popover"
          sx={{ ...POPOVER_STYLE }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {PART_NUMBER}: {partNum}
          <img src={DOMPurify.sanitize(imgURL)} alt="no-image" />
        </Popover>
        <Paper sx={{ ...WIDTH_100, mb: 2 }}>
          <TableContainer>
            <Table
              className={props.fullWidth ? `table ${props.className}` : `table`}
              sx={props.fullWidth ? MIN_WIDTH_100 : MIN_WIDTH_750}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                headCells={headCells}
                props={props.source}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
              />
              <TableBody>
                {visibleRows && visibleRows.length > 0 ? (
                  visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    return (
                      <StyledTableRow
                        hover
                        testid={FILTER_SEARCH}
                        onClick={(event) => handleClick(event, row.id)}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        sx={ROW_STYLE}
                      >
                        {Object.entries(row).map((item, key) => {
                          return (
                            <StyledTableCell
                              style={{
                                backgroundColor:
                                  props.qualification?.toLowerCase() ===
                                    item[0] ||
                                  (props.qualification === THREAD_SIZE &&
                                    item[0] === THREAD_SIZE_VALUE)
                                    ? "rgb(117 160 255)"
                                    : "",
                              }}
                              align="left"
                              key={key}
                            >
                              {(props.source === FILTER_SEARCH_TYPE &&
                                item[0] === FIELDSKU) ||
                              (props.source === VIN_SEARCH_TYPE &&
                                item[0] === FIELDSKU) ? (
                                <Box
                                  display="flex"
                                  flexDirection={{ xs: "column", md: "row" }}
                                >
                                  <ButtonLayout
                                    sx={BTN_STYLE}
                                    className="full-width"
                                    testid={NAPA_PROLINK_URL}
                                    onClickBtn={() => {
                                      window.open(
                                        `${NAPA_PROLINK_URL}${item[1]}${TRACKER}`,
                                        "_blank"
                                      );
                                    }}
                                    color="primary"
                                    label={SHOP_PROLINK_BTN}
                                  />
                                  <ButtonLayout
                                    sx={BTN_STYLE_NOL}
                                    className="full-width"
                                    testid={NAPA_ONLINE_URL}
                                    onClickBtn={() => {
                                      window.open(
                                        `${NAPA_ONLINE_URL}${item[1]}${TRACKER}`,
                                        "_blank"
                                      );
                                    }}
                                    color="primary"
                                    label={SHOP_ONLINE_BTN}
                                  />
                                </Box>
                              ) : props.source === VIN_SEARCH_TYPE &&
                                item[0] === "imageValue" ? (
                                <img
                                  src={`${NAPA_MEDIA_URL}${item[1]}`}
                                  alt="no-img"
                                  height="50px"
                                  width="50px"
                                />
                              ) : (props.source === FILTER_SEARCH_TYPE &&
                                  item[0] === NAPA_PARTNUMBER) ||
                                (props.source === PARTIAL_SEARCH_TYPE &&
                                  item[0] === NAPA_PARTNUMBER) ||
                                (props.source === VIN_SEARCH_TYPE &&
                                  item[0] === NAPA_PARTNUMBER) ? (
                                <LinkView>
                                  <div
                                    testid={NAPA_PARTNUMBER}
                                    onClick={() => onPressLink(item[1], index)}
                                  >
                                    {" "}
                                    {item[1]}
                                  </div>
                                </LinkView>
                              ) : (props.source === APPLICATION_LOOKUP_TYPE &&
                                  item[0] === OIL) ||
                                (props.source === APPLICATION_LOOKUP_TYPE &&
                                  item[0] === FUEL) ||
                                (props.source === APPLICATION_LOOKUP_TYPE &&
                                  item[0] === AIR) ||
                                (props.source === APPLICATION_LOOKUP_TYPE &&
                                  item[0] === CAIR) ||
                                (props.source === APPLICATION_LOOKUP_TYPE &&
                                  item[0] === TRANS) ||
                                (props.source === APPLICATION_LOOKUP_TYPE &&
                                  item[0] === BREATHER) ||
                                (props.source === APPLICATION_LOOKUP_TYPE &&
                                  item[0] === HYDRAULIC) ||
                                (props.source === APPLICATION_LOOKUP_TYPE &&
                                  item[0] === OTHERS) ? (
                                item[1].length > 0 &&
                                item[1].map((filter, i) => (
                                  <div>
                                    <LinkView key={i}>
                                      <div
                                        testid={OIL}
                                        onClick={() => onPressLink(filter)}
                                      >
                                        {" "}
                                        {filter.partNumbers}
                                      </div>
                                    </LinkView>
                                  </div>
                                ))
                              ) : props.source === FILTER_BY_SIZE_TYPE &&
                                item[0] === PARTNUMBER ? (
                                <LinkView>
                                  <>
                                    <div
                                      testid={PARTNUMBER}
                                      onMouseEnter={(e) => {
                                        handlePopoverOpen(e, index);
                                      }}
                                      onMouseLeave={handlePopoverClose}
                                      value={item[1]}
                                      aria-owns={
                                        open ? "mouse-over-popover" : undefined
                                      }
                                      aria-haspopup="true"
                                      onClick={() => {
                                        onPressLink(item[1], index);
                                      }}
                                    >
                                      {" "}
                                      {item[1]?.toUpperCase()}
                                    </div>
                                  </>
                                </LinkView>
                              ) : (
                                item[1]?.toUpperCase()
                              )}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <Box className="p-3" sx={{ ...FONT_SIZE_0875REM }}>
                    {NO_DATA_FOUND_TEXT}
                  </Box>
                )}
                {emptyRows > 0 && (
                  <StyledTableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {visibleRows && visibleRows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={noOfPages}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Box>
      <SimpleSnackbar apiError={apiError} />
    </>
  );
});
export default DataTable;
