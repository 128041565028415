import Grid from "@mui/material/Grid";

const GridItem = (props) => {
  return (
    <Grid
      item
      xs={props.xs && props.xs}
      md={props.md && props.md}
      lg={props.lg && props.lg}
      sm={props.sm && props.sm}
      xl={props.xl && props.xl}
    >
      {props.children}
    </Grid>
  );
};

export default GridItem;
