import React, { memo } from "react";
import DataTable from "../common/dataTable/DataTable";
import { TextTypography } from "../common/typography/TextTypography";
import { useMediaQuery, useTheme } from "@mui/material";
import DataList from "../common/dataList/DataList";
import "./PartDetail.css";
import {
  ALL_APPLICATIONS_LIST_TITLE,
  ALL_APPLICATIONS_LIST_TYPE,
} from "../../utils/constants/GlobalConstants";

const AllApplicationsList = memo((props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <div className="application-list-text-wrapper">
        <TextTypography
          text={`${ALL_APPLICATIONS_LIST_TITLE} ${props?.partNum}`}
        />
      </div>
      {isMobile ? (
        <DataList
          source={ALL_APPLICATIONS_LIST_TYPE}
          filterSearchRes={props?.allAppsData}
        />
      ) : (
        <DataTable
          source={ALL_APPLICATIONS_LIST_TYPE}
          filterSearchRes={props?.allAppsData}
        />
      )}
    </>
  );
});
export default AllApplicationsList;
