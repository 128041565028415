/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable  react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { List, ListItem, ListItemText } from "@mui/material";
import ButtonLayout from "../../components/common/button/ButtonLayout";
import {
  PRINT_LABEL,
  CLOSE_LABEL,
  SUB_COLOR,
  TEXT_MD,
  M8,
  DISPLAY_FLEX_END,
  FONT700,
  PT_10,
  TXT_CENTER,
  TEXT_LG,
  COLOR_BLACK,
  MR_10,
  P5,
  partDetailsRightPanel,
  FONT_NORMAL,
  leftPanelTableHeading,
  leftPanelTableWrapper,
  LIST_ITEM,
  ShopOnlineBtnStyle,
  TECH_SEC_HEADING,
  PROP_65_HEADING,
  PART_DETAILS_MODAL_HEADING,
  PRODUCT_SPEC_TITLE,
  GASKET_DIA_TITLE,
  PRINCIPAL_APP_TEXT,
  APPL_LINK_TEXT,
  REFERENCE_TEXT,
  PROP_TAB,
  VIDEO_TAB,
  IMAGE_TAB,
  INSTRU_TAB,
  TECH_BULLETINS_TAB,
  LIST_ITEM_TEXT,
  WIDTH_40,
  COLOR_RGB_37_99_235,
  WIDTH_45,
  COLOR_000,
  WIDTH_15,
  MB_10,
  SHOP_PROLINK_BTN,
  MR_2,
  GASKET_DIAMETER_TYPE,
  UNKNOWN_VALUE,
  APPLICATION,
  DISPLAY_MENU,
} from "../../utils/constants/GlobalConstants";
import {
  PRODUCT_DETAIL_NAV_ITEMS,
  NAPA_MEDIA_URL,
  NAPA_ONLINE_URL,
  TRACKER,
  NAPA_PROLINK_URL,
} from "../../utils/constants/GlobalFormConstants";
import { useTheme, useMediaQuery } from "@mui/material";
import "./PartDetail.css";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { TextTypography } from "../common/typography/TextTypography";
import LinkView from "../common/link/LinkView";
import DialogView from "../common/dialogView/DialogView";
import AllApplicationsList from "./PartApplicationsList";
import DataTable from "../common/dataTable/DataTable";
import DataList from "../common/dataList/DataList";
import ImageMagnify from "../common/imageMagnify/ImageMagnify";
import CompetitorsInterchange from "./CompetitorsInterchage";

const TabContent = ({ activeTab, fieldSku, assetIdForImage, thumbImage }) => {
  switch (activeTab) {
    case "Technical Bulletins":
      return <TechSection />;
    case "Instructions":
      return <InstructSection />;
    case "Images":
      return (
        <ImageSection
          fieldSku={fieldSku}
          assetIdForImage={assetIdForImage}
          thumbImage={thumbImage}
        />
      );
    case "Videos":
      return <VideoSection />;
    case "Prop 65":
      return <PropSection />;
    default:
      return null;
  }
};

const ImageSection = (props) => {
  const [currentImage, setCurrentImage] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (props?.assetIdForImage || props?.thumbImage) {
      let images;
      if (props?.thumbImage && props?.assetIdForImage) {
        images = props?.assetIdForImage?.filter((itm) => {
          return itm?.id !== "0";
        });

        images = images?.map((itm) => `${NAPA_MEDIA_URL}${itm?.id}`);
        images.push(`${NAPA_MEDIA_URL}${props?.thumbImage}`);
      } else if (props?.thumbImage) {
        images?.push(`${NAPA_MEDIA_URL}${props?.thumbImage}`);
      } else {
        if (props?.assetIdForImage?.length > 1) {
          images = props?.assetIdForImage?.filter((itm) => {
            return itm?.id !== "0";
          });
          images = images?.map((itm) => `${NAPA_MEDIA_URL}${itm?.id}`);
        } else {
          images = props?.assetIdForImage?.map(
            (itm) => `${NAPA_MEDIA_URL}${itm?.id}`
          );
        }
      }
      setImageArray(images);
      images && setCurrentImage(images[0]);
    }
  }, [props.assetIdForImage, props.thumbImage]);

  return (
    <>
      <div className="part-detail-container">
        <div className="imagemagnify-wrapper">
          <ImageMagnify
            id="map"
            src={currentImage}
            alt={`Thumbnail`}
            className="image-style"
          />
        </div>
        <div className={"row-flex"}>
          {imageArray?.map((image, key, index) => (
            <div className="image-layout" key={index}>
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className="option-image-style"
                style={{
                  border:
                    image === currentImage
                      ? "1px solid rgb(37,99,235, 1)"
                      : "1px solid hsl(240, 5.9%, 90%)",
                }}
                testid="assetImage"
                onClick={() => setCurrentImage(image)}
              />
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          ...DISPLAY_FLEX_END,
        }}
      >
        <ButtonLayout
          sx={{ ...ShopOnlineBtnStyle, ...MR_2 }}
          className={isMobile ? "full-width" : " "}
          onClickBtn={() => {
            window.open(`${NAPA_PROLINK_URL}${props?.fieldSku}${TRACKER}`, "_blank");
          }}
          color="primary"
          testid={SHOP_PROLINK_BTN}
          label={SHOP_PROLINK_BTN}
        />
        <ButtonLayout
          sx={{ ...ShopOnlineBtnStyle }}
          className={isMobile ? "full-width" : " "}
          color="primary"
          label="Shop@NAPAOnline"
          onClickBtn={() => {
            window.open(`${NAPA_ONLINE_URL}${props?.fieldSku}${TRACKER}`, "_blank");
          }}
        />
      </div>
    </>
  );
};

const TechSection = () => {
  return (
    <>
      <Box sx={{ ...PT_10, ...TXT_CENTER, ...FONT700 }}>
        {TECH_SEC_HEADING} :
      </Box>
    </>
  );
};

const InstructSection = () => {
  return <></>;
};

const VideoSection = () => {
  return <></>;
};

const PropSection = () => {
  return (
    <>
      <Box sx={{ ...PT_10, ...TXT_CENTER, ...FONT700 }}>
        {PROP_65_HEADING} :
      </Box>
    </>
  );
};

const PartDetail = memo((props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [activeTab, setActiveTab] = useState("Images");
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [productDetailsData, setProductDetailsData] = useState([]);
  const [fieldSku, setFieldSku] = React.useState();
  const [assetIdForImage, setAssetIdForImage] = React.useState();
  const [allAppsData, setAllAppsData] = useState();
  const [competitorsData, setCompetotorsData] = useState();
  const [partNum, setPartNum] = useState("");
  const [thumbImage, setThumbImage] = React.useState();
  useEffect(() => {
    const finalData = [
      {
        attributeName: "Part Number",
        value: props?.productDetailsData?.finalItemResponse?.partNumber,
      },
      ...(props?.productDetailsData?.finalItemResponse?.attributes
        ?.specificAttributes || []),
    ];
    setProductDetailsData(finalData);
    setFieldSku(props?.productDetailsData?.finalItemResponse?.fieldSku);
    setThumbImage(props?.productDetailsData?.finalItemResponse?.thumbImage);
    setAssetIdForImage(
      props?.productDetailsData?.finalItemResponse?.assetIdsForImage
    );
    let sortedArr =
      props?.productDetailsData?.finalItemResponse?.buyersGuide?.sort((a, b) =>
        a.make.toUpperCase() > b.make.toUpperCase() ? 1 : -1
      );
    let compSortedArr =
      props?.productDetailsData?.finalItemResponse?.interchangePartsInfos?.sort(
        (a, b) => (a.brand.toUpperCase() > b.brand.toUpperCase() ? 1 : -1)
      );
    setAllAppsData(sortedArr);
    setCompetotorsData(compSortedArr);
    setPartNum(props?.productDetailsData?.finalItemResponse?.partNumber);
  }, []);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = (navitem) => {
    if (navitem.menu === activeTab) {
      setActiveTab(navitem.menu);
    }

    setAnchorElNav(null);
  };

  const onPressLink = () => {
    setOpen(true);
  };
  const onPressLink1 = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const print = () => {
    window.print();
  };
  return (
    <>
      <DialogView
        from={"parts"}
        open={open}
        handleClose={() => handleClose()}
        width={"80%"}
        maxWidth={"75em!important"}
        heading={PART_DETAILS_MODAL_HEADING}
        childComponet={
          <AllApplicationsList allAppsData={allAppsData} partNum={partNum} />
        }
      />
      <DialogView
        from={"parts"}
        open={openDialog}
        handleClose={() => handleCloseDialog()}
        width={"80%"}
        maxWidth={"75em!important"}
        heading={"Competitors Interchanges"}
        childComponet={
          <CompetitorsInterchange
            competitorsData={competitorsData}
            partNum={partNum}
          />
        }
      />
      <div
        id="part-detail-section"
        className={isMobile ? "column-flex" : "row-flex"}
      >
        <div className={isMobile ? "left-panel-mob" : "left-panel"}>
          <Box
            sx={{
              ...leftPanelTableWrapper,
              ...MB_10,
            }}
          >
            <Box
              sx={{
                ...leftPanelTableHeading,
              }}
            >
              <TextTypography
                sx={{ ...TEXT_MD, ...M8 }}
                text={PRODUCT_SPEC_TITLE}
              />
            </Box>
            <div className="divider"></div>
            <List>
              {productDetailsData?.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    ...LIST_ITEM,
                    backgroundColor: index % 2 !== 0 && "rgb(239 246 255)",
                  }}
                >
                  <ListItemText
                    sx={{
                      ...WIDTH_40,
                      ...COLOR_RGB_37_99_235,
                      ...LIST_ITEM_TEXT,
                    }}
                    primaryTypographyProps={{
                      ...TEXT_MD,
                      ...FONT_NORMAL,
                    }}
                    primary={item.attributeName}
                  />
                  <ListItemText
                    sx={{
                      ...WIDTH_45,
                      ...COLOR_000,
                      ...LIST_ITEM_TEXT,
                    }}
                    primaryTypographyProps={{
                      ...TEXT_MD,
                      ...FONT_NORMAL,
                    }}
                    primary={item.value}
                  />
                  <ListItemText
                    sx={{
                      ...WIDTH_15,
                      ...COLOR_000,
                      ...LIST_ITEM_TEXT,
                    }}
                    primaryTypographyProps={{
                      ...TEXT_MD,
                      ...FONT_NORMAL,
                    }}
                    primary={item.metric}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box
            sx={{
              ...leftPanelTableWrapper,
              ...MB_10,
            }}
          >
            <Box
              sx={{
                ...leftPanelTableHeading,
              }}
            >
              <TextTypography
                sx={{ ...TEXT_MD, ...M8 }}
                text={GASKET_DIA_TITLE}
              />
            </Box>
            <div className="divider"></div>
            {isMobile ? (
              <DataList
                source={GASKET_DIAMETER_TYPE}
                data={
                  props?.productDetailsData?.finalItemResponse
                    ?.gasketInformation
                }
              />
            ) : (
              <DataTable
                fullWidth="gasket-table"
                source={GASKET_DIAMETER_TYPE}
                data={
                  props?.productDetailsData?.finalItemResponse
                    ?.gasketInformation
                }
              />
            )}
          </Box>
          <Box
            sx={{
              ...leftPanelTableWrapper,
              ...COLOR_000,
            }}
          >
            <Box
              sx={{
                ...leftPanelTableHeading,
              }}
            >
              <TextTypography
                sx={{ ...TEXT_MD, ...M8 }}
                text={PRINCIPAL_APP_TEXT}
              />
            </Box>
            <div className="divider"></div>
            <TextTypography
              sx={{ ...TEXT_MD, ...M8, ...FONT_NORMAL }}
              text={UNKNOWN_VALUE}
            />
          </Box>
          <div className="applications-link-style">
            <LinkView>
              <div onClick={() => onPressLink()}>
                <span style={{ ...TEXT_LG }}>{APPL_LINK_TEXT}</span>
              </div>
            </LinkView>
            {competitorsData && (
              <LinkView>
                <div onClick={() => onPressLink1()}>
                  <span style={{ ...TEXT_LG }}>
                    {"Competitors Interchange"}
                  </span>
                </div>
              </LinkView>
            )}
          </div>
        </div>

        <div className="right-panel">
          <Box
            sx={{
              ...partDetailsRightPanel,
              ...FONT700,
              ...TXT_CENTER,
              alignItems: "center",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {activeTab}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                ...DISPLAY_MENU,
                color: SUB_COLOR,
              }}
            >
              {PRODUCT_DETAIL_NAV_ITEMS.map((navitem, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleCloseNavMenu(navitem)}
                  testid={navitem.menu}
                >
                  <Typography
                    textAlign="center"
                    sx={{ ...COLOR_BLACK }}
                    className={navitem?.menu !== activeTab ? "tab-text" : ""}
                  >
                    {navitem.menu}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <div className="tab-section">
            <a
              data-testid="bulletins-tab"
              className={`tab-button ${
                activeTab === TECH_BULLETINS_TAB ? "active" : "tab-text"
              }`}
              testid={TECH_BULLETINS_TAB}
              onClick={() => setActiveTab(TECH_BULLETINS_TAB)}
            >
              {TECH_BULLETINS_TAB}
            </a>
            <a
              data-testid="instru-tab"
              className={`tab-button ${
                activeTab === INSTRU_TAB ? "active" : "tab-text"
              }`}
              onClick={() => setActiveTab(INSTRU_TAB)}
              testid={INSTRU_TAB}
            >
              {INSTRU_TAB}
            </a>
            <a
              data-testid="images-tab"
              className={`tab-button ${
                activeTab === IMAGE_TAB ? "active" : "tab-text"
              }`}
              testid={IMAGE_TAB}
              onClick={() => setActiveTab(IMAGE_TAB)}
            >
              {IMAGE_TAB}
            </a>
            <a
              data-testid="video-tab"
              className={`tab-button ${
                activeTab === VIDEO_TAB ? "active" : "tab-text"
              }`}
              testid={VIDEO_TAB}
              onClick={() => setActiveTab(VIDEO_TAB)}
            >
              {VIDEO_TAB}
            </a>
            <a
              data-testid="prop-tab"
              className={`tab-button ${
                activeTab === PROP_TAB ? "active" : "tab-text"
              }`}
              onClick={() => setActiveTab(PROP_TAB)}
              testid={PROP_TAB}
            >
              {PROP_TAB}
            </a>
          </div>
          <div>
            <TabContent
              activeTab={activeTab}
              fieldSku={fieldSku}
              thumbImage={thumbImage}
              assetIdForImage={assetIdForImage}
            />
          </div>
        </div>
      </div>
      <div className="reference-text-wrapper">
        <span>{REFERENCE_TEXT}</span>
      </div>
      {props.from !== APPLICATION && (
        <div className="bottom-btn-wrapper">
          <ButtonLayout
            sx={{ ...MR_10, ...P5 }}
            color="primary"
            label={CLOSE_LABEL}
            onClickBtn={() => props.closeDialog()}
            testid={CLOSE_LABEL}
          />

          <ButtonLayout
            sx={{ ...P5 }}
            color="primary"
            testid={PRINT_LABEL}
            label={PRINT_LABEL}
            onClickBtn={() => {
              print();
            }}
          />
        </div>
      )}
    </>
  );
});
export default PartDetail;
