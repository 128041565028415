// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  font-weight: 600;
  margin-top: 10px;
  padding: 2px;
  font-size: 14px !important;
  text-align: center !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
          user-select: none !important;
  color: #001489 !important;
  border: 1px solid 001489;
  border-radius: 3px;
  display: block;
  background: #2563eb;
  background-size: 200% 100%;
  background-position: left bottom;
}

.button:hover {
  background-position: right bottom;
  opacity: 0.9;
}

.text {
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  transition: all 0.6s ease-out;
  display: block;
}

.text:hover {
  color: white;
}

@media (max-width: 899px) {
  .full-width .text {
    font-size: 10px;
  }

  .full-width {
    width: 100% !important;
  }
}

@media (max-width: 1025px) {
  .full-width .text {
    font-size: 12px;
  }

  .full-width {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/button/ButtonLayout.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAEhB,gBAAgB;EAChB,YAAY;EACZ,0BAA0B;EAC1B,6BAA6B;EAC7B,0BAA0B;EAC1B,oCAA4B;UAA5B,4BAA4B;EAC5B,yBAAyB;EACzB,wBAAwB;EACxB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,0BAA0B;EAC1B,gCAAgC;AAClC;;AAEA;EACE,iCAAiC;EACjC,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".button {\n  font-weight: 600;\n  border-radius: 4px !important;\n  margin-top: 10px;\n  padding: 2px;\n  font-size: 14px !important;\n  text-align: center !important;\n  cursor: pointer !important;\n  user-select: none !important;\n  color: #001489 !important;\n  border: 1px solid 001489;\n  border-radius: 3px;\n  display: block;\n  background: #2563eb;\n  background-size: 200% 100%;\n  background-position: left bottom;\n}\n\n.button:hover {\n  background-position: right bottom;\n  opacity: 0.9;\n}\n\n.text {\n  text-align: center;\n  font-size: 16px;\n  line-height: 30px;\n  color: #fff;\n  transition: all 0.6s ease-out;\n  display: block;\n}\n\n.text:hover {\n  color: white;\n}\n\n@media (max-width: 899px) {\n  .full-width .text {\n    font-size: 10px;\n  }\n\n  .full-width {\n    width: 100% !important;\n  }\n}\n\n@media (max-width: 1025px) {\n  .full-width .text {\n    font-size: 12px;\n  }\n\n  .full-width {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
