/* eslint-disable  no-unreachable */
import React, { useState, useRef } from "react";
import GridContainer from "../../components/common/grid/GridContainer";
import GridItem from "../../components/common/grid/GridItem";
import {
  FILTER_BY_SIZE_DROPDOWN_DATA,
  NAPA_MEDIA_URL,
  NAPA_PROLINK_URL,
  NAPA_ONLINE_URL,
  TRACKER,
} from "../../utils/constants/GlobalFormConstants";
import { TextTypography } from "../../components/common/typography/TextTypography";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
  CardMedia,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import "./ProductComparison.css";
import HoverPopover from "../../components/common/popover/PopOver";
import { Dropdown } from "../../components/common/Dropdown/Dropdown";
import {
  BGCOLOR_EFF6FF,
  HOME_MAIN_STYLE,
  MARGIN_PADD_02,
  WIDTH_100_px,
  COMPARISON_PART_POPOVER,
  YEAR_VALIDATION,
  COMPARE_MSG,
  PRODUCT_COMP_HEADING,
  PRODUCT_COMPARE_MSG1,
  POPOVER_MSG1,
  MT_40,
  MB_10,
  MR_10,
  DISPLAY_FLEX_CENTER,
  BTN_STYLE,
  BTN_STYLE_NOL,
  SHOP_ONLINE_BTN,
  SHOP_PROLINK_BTN
} from "../../utils/constants/GlobalConstants";
import { getProductMakesORModels } from "../../services/ProductServices";
import SimpleSnackbar from "../../components/common/snackBar/SnackBar";
import ButtonLayout from "../../components/common/button/ButtonLayout";
import SimpleBackdrop from "../../components/common/backdrop/BackDrop";
import { ErrorBoundary } from "../../components/common/errorBoundary/ErrorBuond";

export const ProductComparison = () => {
  const resultRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedFromYear, setSelectedFromYear] = useState(null);
  const [selectedToYear, setSelectedToYear] = useState(null);
  const [selectedOptions] = useState([]);
  const [showResultContainer, setShowResultContainer] = useState(false);
  const [apiError, setApiError] = useState({ val: false, messages: "" });
  const [makesData, setMakesData] = useState([]);
  const [modelsData, setModelsData] = useState([]);
  const [selectedMakes, setSelectedMakes] = useState(null);

  const [selectedType, setSelectedType] = useState(null);
  const [selectedModels, setSelectedModels] = useState([]);
  const [productRes, setResultData] = useState([]);
  const [partNumData, setPartNumData] = useState([]);
  const [compare, setCompare] = useState(false);
  const [productType, setProductType] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState();
  const [years, setYears] = useState([]);
  const [fromYears, setFromYears] = useState([]);
  const [fullModels, setFullModels] = useState([]);
  const [productResCopy, setResultDataCopy] = useState([]);
  const [loader, setLoader] = useState(false);
  const [checkedProducts, setCheckedProducts] = useState([]);

  const handleResultContainer = () => {
    setShowResultContainer(true);
    if (resultRef) {
      setTimeout(() => {
        resultRef?.current.scrollIntoView({ behavior: "smooth" });
      });
    }
  };

  const getModels = async (payload) => {
    try {
      const modelRes = await getProductMakesORModels(payload);
      const models = modelRes?.vehicleResponse?.vehicleModel?.map(
        (item, i) => ({
          value: i,
          vehicleId: item.vehicleId,
          label: item.model,
        })
      );
      setFullModels(models);
      const uniquePartNums = models.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.label === value.label)
      );
      setModelsData(uniquePartNums);
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };

  const onSelectToYear = async (val) => {
    setResultData(null);
    setPartNumData(null);

    setSelectedModels(null);
    setModelsData(null);

    if (parseInt(selectedFromYear?.label) > parseInt(val?.label)) {
      alert(YEAR_VALIDATION);
      setSelectedToYear(null);
    } else {
      if (val) {
        setSelectedToYear(val);
        let payload = {
          filterType: selectedFilter,
          type: selectedType,
          make: selectedMakes?.label,
          years: [selectedFromYear?.label, val?.label],
          apiType: "product_comparison_models",
        };
        getModels(payload);
      } else {
        setSelectedToYear(null);
      }
    }
  };
  const onSetFilter = async (val) => {
    setSelectedFromYear(null);
    setSelectedToYear(null);
    setSelectedModels(null);
    setYears(null);
    setFromYears(null);
    setModelsData(null);
    setSelectedMakes(null);
    setMakesData(null);
    setProductType(null);
    setSelectedType(null);
    setResultData(null);
    setPartNumData(null);

    if (val) {
      setSelectedFilter(val?.value);
      let payload = {
        filterType: val.label,
        apiType: "product_comparison_types",
      };
      try {
        const typeRes = await getProductMakesORModels(payload);
        const types = typeRes?.vehicleResponse?.vehicleTypes?.map(
          (item, i) => ({
            value: item.typeId,
            label: item.type,
          })
        );

        setProductType(types);
      } catch (error) {
        setApiError((val) => ({ ...val, val: true, message: error.message }));
      }
    } else {
      setSelectedFilter(null);
    }
  };
  const onSetProductType = async (val) => {
    setSelectedFromYear(null);
    setSelectedToYear(null);
    setSelectedModels(null);
    setYears(null);
    setFromYears(null);
    setModelsData(null);
    setSelectedMakes(null);
    setMakesData(null);
    setResultData(null);
    setPartNumData(null);

    if (val) {
      setSelectedType(val?.label);
      let payload = {
        filterType: selectedFilter,
        type: val.label,
        apiType: "product_comparison_makes",
      };
      try {
        const typeRes = await getProductMakesORModels(payload);
        const types = typeRes?.vehicleResponse?.vehicleMake?.map((item, i) => ({
          value: item.makeId,
          label: item.make,
        }));

        setMakesData(types);
      } catch (error) {
        setApiError((val) => ({ ...val, val: true, message: error.message }));
      }
    } else {
      setSelectedType(null);
    }
  };

  const toYearHelper = (year, latestYears) => {
    let fromYr = Number(year.label);
    const filtered = latestYears.filter((y) => Number(y.label) > fromYr);
    setYears(filtered);
  };

  const getYears = async (payload, fromYr) => {
    try {
      const yearRes = await getProductMakesORModels(payload);
      const years = yearRes?.vehicleResponse?.vehicleYear.map((item, i) => ({
        value: i,
        label: item,
      }));

      setYears(years);
      let latestYears = years;
      fromYr && toYearHelper(fromYr, latestYears);
      setFromYears(years);
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };

  const onSetMake = async (val) => {
    setSelectedFromYear(null);
    setSelectedToYear(null);
    setSelectedModels(null);
    setYears(null);
    setFromYears(null);

    setModelsData(null);
    setResultData(null);
    setPartNumData(null);

    if (val) {
      setSelectedMakes(val);
      let payload = {
        filterType: selectedFilter,
        type: selectedType,
        make: val.label,
        apiType: "product_comparison_years",
      };
      getYears(payload, selectedFromYear);
    } else {
      setSelectedMakes(null);
    }
  };

  const onSetModel = async (val) => {
    setCompare(false);
    if (val) {
      setSelectedModels(val);
      let vehicleIds = fullModels?.filter((item) =>
        item.label === val.label ? item.vehicleId : ""
      );
      let arr = vehicleIds?.map((item) => item.vehicleId);
      let payload = {
        filterType: selectedFilter,
        type: selectedType,
        make: selectedMakes?.label,
        years: [selectedFromYear?.label, selectedToYear?.label],
        apiType: "product_comparison_products",
        vehicleIds: arr,
      };
      try {
        setLoader(true);
        const response = await getProductMakesORModels(payload);
        const partNumbersData = response.finalItemResponseList?.map(
          (item, i) => ({
            value: i,
            label: item.partNumber,
            checked: false,
            pid: item.pid,
          })
        );
        setPartNumData(partNumbersData);
        const updatedResponse = response.finalItemResponseList?.map((item) => ({
          ...item,
          checked: false,
        }));
        setResultDataCopy(updatedResponse);
        setResultData(updatedResponse);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        setApiError((val) => ({ ...val, val: true, message: error.message }));
      }

      handleResultContainer();
    } else {
      setSelectedModels(null);
    }
  };
  const handleResultSetForSelected = (products) => {
    setResultData(products);
  };
  const onSetSelectedPart = (val) => {
    let existingProducts = checkedProducts;
    let newProduct = productResCopy.filter((item) => item.pid === val.pid);
    if (existingProducts?.length > 0) {
      if (
        existingProducts.filter((item) => item.pid === val.pid).length === 0
      ) {
        existingProducts.push(...newProduct);
        setCheckedProducts((existingProducts) =>
          existingProducts.filter((item) => item)
        );
      } else {
        existingProducts = existingProducts.filter(
          (item) => item.pid !== val.pid
        );
        setCheckedProducts((existingProducts) =>
          existingProducts.filter((item) => item.pid !== val.pid)
        );
      }
    } else {
      existingProducts.push(...newProduct);
      setCheckedProducts(newProduct);
    }
    handleResultSetForSelected(existingProducts);
  };

  const selectItem = (pid) => {
    setResultData((resultData) =>
      resultData.map((item) =>
        item.pid === pid ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const onClickCompare = () => {
    if (productRes.filter((item) => item.checked).length <= 4) {
      let selectedProducts = productRes?.filter((item) =>
        item.checked === true ? item : ""
      );
      let unchekProduct = selectedProducts.map((item) =>
        item.checked === true ? { ...item, checked: false } : item
      );
      setCheckedProducts(unchekProduct);
      setResultData(selectedProducts);
      setCompare(true);
    } else {
      alert(COMPARE_MSG);
    }
  };

  const handleDelete = (model) => {
    setCompare(false);
    let deletePRoducts = productRes.filter((item) =>
      item.partNumber === model ? "" : item
    );
    if (deletePRoducts?.length > 0) {
      setCheckedProducts(deletePRoducts);
      setResultData(deletePRoducts);
    } else {
      setCheckedProducts([]);
      setResultData(productResCopy);
    }
  };

  const handleFromYearChange = (val) => {
    setSelectedModels(null);
    setModelsData(null);
    setResultData(null);
    setPartNumData(null);
    setYears(null);
    setSelectedToYear(null);
    if (val) {
      setSelectedFromYear(val);
      let payload = {
        filterType: selectedFilter,
        type: selectedType,
        make: selectedMakes?.label,
        apiType: "product_comparison_years",
      };
      getYears(payload, val);
    } else {
      setSelectedFromYear(null);
    }
  };

  const handleDisable = (type) => {
    switch (type) {
      case "VehicleType":
        if (selectedFilter) {
          return false;
        } else {
          return true;
        }
        break;
      case "Make":
        if (selectedType) {
          return false;
        } else {
          return true;
        }
        break;
      case "FromYear":
        if (selectedMakes) {
          return false;
        } else {
          return true;
        }
        break;
      case "ToYear":
        if (selectedFromYear) {
          return false;
        } else {
          return true;
        }
        break;
      case "Model":
        if (selectedToYear) {
          return false;
        } else {
          return true;
        }
      case "PartNumber":
        if (selectedModels) {
          return false;
        } else {
          return true;
        }
        break;
      default:
        return false;
    }
  };

  return (
    <ErrorBoundary>
      <div className="layout" style={HOME_MAIN_STYLE}>
        <SimpleBackdrop open={loader} />

        <GridContainer
          rowSpacing={5}
          columnSpacing={3}
          sx={{ ...MB_10, ...MT_40 }}
        >
          <Box className={isMobile ? "header-mob" : "header-desk"}>
            <h1 style={{ fontSize: "25px", fontWeight: "700" }}>
              {PRODUCT_COMP_HEADING}
            </h1>
            <div>{PRODUCT_COMPARE_MSG1}</div>
          </Box>
          <GridItem xs={12} sm={12} lg={12} md={12} xl={12}>
            <div className="mt-8">
              <Box
                component="fieldset"
                className="fieldset-style-main grid-align"
              >
                <TextTypography text="Product Type" />
                <Dropdown
                  label="Start by Selecting a Product Type"
                  data={FILTER_BY_SIZE_DROPDOWN_DATA}
                  selectValue={(val) => onSetFilter(val)}
                  type={"productCompare-productype"}
                  testid={"ProductType"}
                />
              </Box>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} lg={5} md={5} xl={5}>
            <Box component="fieldset" className="fieldset-style-main left-grid">
              <TextTypography text="Vehicles" />
              <HoverPopover popoverTxt={POPOVER_MSG1} />
              <Dropdown
                label="Vehicle Type"
                data={productType}
                type={"productCompare-VehicleType"}
                selectValue={(val) => onSetProductType(val)}
                testid={"Product Type(s))"}
                disabled={handleDisable("VehicleType")}
              />
              <Dropdown
                label="Make"
                data={makesData}
                type={"productCompare-VehicleType"}
                selectValue={(val) => onSetMake(val)}
                testid={"Make(s)"}
                disabled={handleDisable("Make")}
              />

              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  lg={5.5}
                  md={5.5}
                  xl={5.5}
                  className="pt-0pb-0"
                >
                  <Dropdown
                    label="From Year"
                    data={fromYears}
                    selectValue={(val) => handleFromYearChange(val)}
                    type={"year"}
                    testid={"From Year"}
                    inverseSort={true}
                    disabled={handleDisable("FromYear")}
                  />
                </GridItem>
                <GridItem lg={1} md={1} xl={1} />

                <GridItem
                  xs={12}
                  sm={12}
                  lg={5.5}
                  md={5.5}
                  xl={5.5}
                  className="pt-0pb-0"
                >
                  <Dropdown
                    label="To Year"
                    data={years}
                    selectValue={(val) => onSelectToYear(val)}
                    type={"year"}
                    testid={"To Year"}
                    inverseSort={true}
                    disabled={handleDisable("ToYear")}
                  />
                </GridItem>
              </GridContainer>

              {selectedMakes && selectedMakes.length > 0 && (
                <div style={{ marginBottom: "20px" }}>
                  {selectedMakes.map((make, i) => (
                    <Chip
                      key={i}
                      label={make}
                      variant="outlined"
                      onDelete={handleDelete}
                      sx={{ ...MR_10 }}
                      testid={"chip"}
                    />
                  ))}
                </div>
              )}
              <Dropdown
                label="Model"
                data={modelsData}
                selectValue={(val) => onSetModel(val)}
                type={"productCompare-VehicleType"}
                testid="Model(s)"
                disabled={handleDisable("Model")}
              />
            </Box>
          </GridItem>
          <GridItem xs={12} sm={12} lg={5} md={5} xl={5}>
            <Box className="fieldset-style-main left-grid">
              <TextTypography text="Part Number" />
              <div className="mb-2">
                <HoverPopover popoverTxt={COMPARISON_PART_POPOVER} />
              </div>
              <Dropdown
                data-testid="partnum"
                label="Part Number"
                data={partNumData}
                disabled={handleDisable("PartNumber")}
                selectValue={(val) => onSetSelectedPart(val)}
                testid={"partNum"}
                type={"productCompare-VehicleType"}
              />
              {checkedProducts && checkedProducts.length > 0 && (
                <div style={{ marginBottom: "10px" }}>
                  {checkedProducts.map((model, i) => (
                    <Chip
                      key={i}
                      label={model.partNumber}
                      variant="outlined"
                      onDelete={() => handleDelete(model.partNumber)}
                      sx={{ ...MR_10 }}
                    />
                  ))}
                </div>
              )}{" "}
            </Box>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} lg={12} md={12} xl={12}>
            <div ref={resultRef}></div>
            {showResultContainer && productRes && productRes.length > 0 && (
              <div
                className={
                  isMobile ? "" : { marginRight: "150px", marginLeft: "150px" }
                }
              >
                <GridItem lg={1} md={1} xl={1} />
                <GridItem xs={12} sm={12} lg={12} md={12} xl={12}>
                  <div className="item-container">
                    <TextTypography
                      text={`SEARCH RESULT FOR: ${selectedFilter} ${selectedType} ${selectedFromYear?.label}-${selectedToYear?.label} ${selectedMakes?.label} ${selectedModels?.label}`}
                      marginBottom={"0px !important"}
                    />
                  </div>
                  {selectedOptions.map((option, index) => (
                    <label key={index}>{option}</label>
                  ))}
                </GridItem>
                <div
                  className="filter-container"
                  style={{ ...DISPLAY_FLEX_CENTER }}
                ></div>
                <div
                  spacing={3}
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {productRes &&
                    productRes.length > 0 &&
                    productRes.map((product, i) => (
                      <div
                        style={
                          !isMobile
                            ? {
                              width: "25%",
                              padding: "10px",
                            }
                            : {
                              width: "400px",
                              padding: "10px",
                            }
                        }
                      >
                        <Card key={i}>
                          <CardContent
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "3px",

                              ...MARGIN_PADD_02,
                              ...BGCOLOR_EFF6FF,
                              border: "1px solid #EFF6FF",
                            }}
                          >
                            <div />
                            <div />
                            <Typography
                              gutterBottom
                              variant="body"
                              component="body"
                              noWrap
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            >
                              {product.partNumber}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={product.checked}
                                  onChange={() => selectItem(product.pid)}
                                  style={{ color: "black" }}
                                />
                              }
                              label="Select"
                              labelPlacement="start"
                              data-testid="product-checkbox"
                            />
                          </CardContent>
                          <CardMedia
                            style={{
                              padding: "3px",
                              height: "170px",
                              width: "170px",
                              alignItems: "center",
                              margin: "auto",
                            }}
                            component="img"
                            alt="Image 1"
                            image={`${NAPA_MEDIA_URL}${product?.thumbImage}`}
                          />
                          {compare && (
                            <>
                              <TableContainer>
                                <Table>
                                  <TableBody>
                                    {product.attributes.specificAttributes.map(
                                      (row, index) => (
                                        <TableRow key={index}>
                                          <TableCell>
                                            <span style={{ fontWeight: "600" }}>
                                              {row.attributeName}
                                            </span>
                                          </TableCell>
                                          <TableCell>{row.value}</TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <Box
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                              >
                                <ButtonLayout
                                  sx={BTN_STYLE}
                                  className="full-width"
                                  testid={NAPA_PROLINK_URL}
                                  onClickBtn={() => {
                                    window.open(
                                      `${NAPA_PROLINK_URL}${product?.fieldSku}${TRACKER}`,
                                      "_blank"
                                    );
                                  }}
                                  color="primary"
                                  label={SHOP_PROLINK_BTN}
                                />
                                <ButtonLayout
                                  sx={BTN_STYLE_NOL}
                                  className="full-width"
                                  testid={NAPA_ONLINE_URL}
                                  onClickBtn={() => {
                                    window.open(
                                      `${NAPA_ONLINE_URL}${product?.fieldSku}${TRACKER}`,
                                      "_blank"
                                    );
                                  }}
                                  color="primary"
                                  label={SHOP_ONLINE_BTN}
                                />
                              </Box>
                            </>
                          )}
                        </Card>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {productRes && productRes.length > 0 && (
              <GridContainer>
                <GridItem xs={12} sm={12} lg={12} md={12} xl={12}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "30px",
                    }}
                  >
                    <div>
                      Use the check box on each tile to narrow the scope of
                      products you want to compare(Select up to 4 products),
                      then toggle the compare button
                    </div>
                    <ButtonLayout
                      label={"COMPARE"}
                      sx={
                        productRes.filter((item) => item.checked).length >= 2
                          ? { ...WIDTH_100_px }
                          : {
                            width: "100px",
                            opacity: 0.5,
                            pointerEvents: "none",
                          }
                      }
                      disabled={
                        productRes.filter((item) => item.checked).length >= 2
                      }
                      onClickBtn={() => onClickCompare()}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            )}
            {showResultContainer && productRes && productRes.length === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30px",
                  color: "gray",
                }}
              >
                No data found.
              </div>
            )}
          </GridItem>
        </GridContainer>
        <SimpleSnackbar apiError={apiError} />
      </div>
    </ErrorBoundary>
  );
};
