import "./TextInput.css";

const TextInput = (props) => {
  return (
    <input
      className={
        props.className
          ? props.className
          : "h-10 p-4 bg-blue-50 dark:bg-slate-700 rounded-xl border-none focus:outline-none mb-3 w-full text-sm"
      }
      {...props}
    />
  );
};

export default TextInput;
