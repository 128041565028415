import React from "react";
import Typo from "../typo/Typo";

export const AppH5Header = (props) => {
  return (
    <div className="centerText section-gap bold-text">
      <Typo variant="h6" text={props.text} color="secondary" />
    </div>
  );
};

export const AppH6Header = (props) => {
  return (
    <div>
      <Typo variant="h6" text={props.text} color="secondary" />
    </div>
  );
};

export const AppBody2Text = (props) => {
  return (
    <div>
      <Typo variant="body2" text={props.text} color="secondary" />
    </div>
  );
};
