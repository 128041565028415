import { postRequest } from "./RequestConfig";
export const competitorInterchangeData = async (data) => {
  try {
    const response = await postRequest("filters/partNumberDetails", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const vinsearchData = async (data) => {
  try {
    const response = await postRequest("filters/vinNumberSearch", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getDynamicSearchDetails = async (num) => {
  try {
    let partNumbers = [num];
    const response = await postRequest(`filters/partNumberDetails`, {
      apiName: "partial",
      partNumbers: partNumbers,
    });
    return response.data.partNumberDetailsList;
  } catch (error) {
    throw error;
  }
};

export const getProductMakesORModels = async (payload) => {
  try {
    const response = await postRequest(`filters/productComparison`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
