// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-container{
    zoom: 0.74;
}
.MuiPaper-root{
 margin-bottom: 0px !important;
}
.MuiMenuItem-root{
    font-family:"ui-sans-serif, system-ui, sans-serif, Segoe UI Emoji,Segoe UI Symbol, Noto Color Emoji" !important;
    font-Size: 10px !important
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;CACC,6BAA6B;AAC9B;AACA;IACI,+GAA+G;IAC/G;AACJ","sourcesContent":[".app-container{\n    zoom: 0.74;\n}\n.MuiPaper-root{\n margin-bottom: 0px !important;\n}\n.MuiMenuItem-root{\n    font-family:\"ui-sans-serif, system-ui, sans-serif, Segoe UI Emoji,Segoe UI Symbol, Noto Color Emoji\" !important;\n    font-Size: 10px !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
