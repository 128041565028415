import Grid from "@mui/material/Grid";

const GridContainer = (props) => {
  return (
    <Grid container spacing={props.spacing && props.spacing} {...props}>
      {props.children}
    </Grid>
  );
};

export default GridContainer;
