/* eslint-disable  react-hooks/exhaustive-deps */
import { getRequest, postRequest } from "./RequestConfig";
export const getPartTypes = async () => {
  try {
    const response = await getRequest("filters/partTypes");
    return response.data.partTypes;
  } catch (error) {
    throw error;
  }
};

export const getPartTypeChange = async (data) => {
  if (
    (data?.apiName === "measurements" && data?.style) ||
    data?.apiName === "style"
  ) {
    try {
      const response = await postRequest("filters/dimensions", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export const getStyles = async (partType) => {
  try {
    const response = await getRequest(`filters/styles?partType=${partType}`);
    return response.data.styles;
  } catch (error) {
    throw error;
  }
};

export const getDimensionDetails = async (
  partType,
  SelectedStyle,
  qualificationValue,
  qualification
) => {
  const payload = {
    partType: partType,
    apiName: "dimensionDetails",
    style: SelectedStyle,
    qualificationValue: qualificationValue,
    qualification: qualification,
  };
  if (payload?.qualificationValue && payload?.style) {
    try {
      const response = await postRequest("filters/dimensions", payload);
      return response.data.dimensionDetails;
    } catch (error) {
      throw error;
    }
  }
};
