import "./ButtonLayout.css";
import { Box } from "@mui/material";

const ButtonLayout = (props) => {
  return (
    <Box
      className={props.className ? `button ${props.className}` : `button`}
      onClick={props.onClickBtn}
      sx={props.sx}
    >
      <Box className="text">{props.label}</Box>
    </Box>
  );
};

export default ButtonLayout;
