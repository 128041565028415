import "../home/styles/DynamicSearchSpeechRecognition.css";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DYNAMIC_POPUP,
  LISTENING_TEXT,
} from "../../utils/constants/GlobalConstants";

function DynamicSearchSpeechRecognition() {
  return (
    <div style={DYNAMIC_POPUP}>
      <div className="microphone-icon-container">
        <FontAwesomeIcon className="microphone-icon" icon={faMicrophone} />
      </div>
      {LISTENING_TEXT}
    </div>
  );
}
export default DynamicSearchSpeechRecognition;
