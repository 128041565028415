/* eslint-disable  react-hooks/exhaustive-deps */
import { Box, ClickAwayListener, List, ListItem } from "@mui/material";
import React from "react";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextTypography } from "../common/typography/TextTypography";
import DialogView from "../common/dialogView/DialogView";
import DynamicSearchSpeechRecognition from "./DynamicSearchSpeechRecognition";
import { useEffect, useRef, useState } from "react";
import "../home/styles/DynamicSearchSpeechRecognition.css";
import SimpleSnackbar from "../common/snackBar/SnackBar";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  competitorInterchangeData,
  getDynamicSearchDetails,
} from "../../services/ProductServices";
import { DebounceInput } from "react-debounce-input";
import {
  CURSOR_POINTER,
  DYNAMIC_ITEM_STYLE,
  DYNAMIC_LAYER,
  DYNAMIC_SEARCH_HEAD,
  DYNAMIC_SEARCH_TYPE,
  FILTER_ABBR,
  NO_DATA_FOUND_TEXT,
  PARTNUM_SEARCH,
  PART_NUMBER,
  ERR_MSG_PART_NUMBER,
  POS_RELATIVE,
} from "../../utils/constants/GlobalConstants";
import { VALID_SEARCH_REGEX } from "../../utils/constants/GlobalFormConstants";
import SimpleBackdrop from "../common/backdrop/BackDrop";
const DynamicSearch = (props) => {
  const [isListening, setIsListening] = useState(false);
  const [showPartDetailsContainer, setShowPartDetailsContainer] =
    useState(false);
  const [value, setValue] = React.useState("");
  const [apiError, setApiError] = useState({ val: false, messages: "" });
  const [dynamicSearchData, setDynamicSearchData] = useState();
  const [loader, setLoader] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [disableSpeaker, setDisableSpeaker] = useState(false);
  const regex = VALID_SEARCH_REGEX;
  const microphoneRef = useRef(null);
  const recognition = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchTerm,setSearchTerm] = useState('');

  useEffect(()=>{
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognition.current=new SpeechRecognition();
    recognition.current.lang = 'en-US';
    recognition.current.onresult=(event)=>{
      const tran = Array.from(event.results).map((result)=>result[0].transcript).join('');
      const cleanTrans = tran.replace(/[.!?]*$/,'').trim();
      setSearchTerm(cleanTrans);
    }
    recognition.current.onerror=(event)=>{
      console.error('error',event.error);
    }
  },[isListening]);

  const startReco = ()=>{
    recognition.current.start();
  }

  const stopReco = ()=>{
    recognition.current.stop();
    setIsListening(false);
  }

  const handleDynamicData = async () => {
    if (value && value.length > 1 && isValid) {
      setLoader(true);

      try {
        const dynamicData = await getDynamicSearchDetails(value);

        let filterAbbrData = dynamicData[0]?.partNumberDetails?.filter((item) =>
          FILTER_ABBR?.includes(item?.abbreviation)
        );
        const uniquePartNums = filterAbbrData.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t.partialPartNumber === value.partialPartNumber
            )
        );
        let sortedPartNums = uniquePartNums.sort(function (a, b) {
          if (a.partialPartNumber < b.partialPartNumber) return -1;
          if (a.partialPartNumber > b.partialPartNumber) return 1;
          return 0;
        });
        setDynamicSearchData(sortedPartNums);
        if (sortedPartNums?.length === 0) {
          resetMic(3000);
        }
        setShowPartDetailsContainer(true);
        setLoader(false);
        setDisableSpeaker(true);
      } catch (error) {
        setDisableSpeaker(true);
        setApiError((val) => ({ ...val, val: true, message: error.message }));
        setShowPartDetailsContainer(false);
        setLoader(false);
        resetMic(3000);
      }
    } else if (!isValid) {
      setApiError((val) => ({
        ...val,
        val: true,
        message: ERR_MSG_PART_NUMBER,
      }));
      setShowPartDetailsContainer(false);
      setIsValid(true);
      resetMic(4500);
    } else {
      setShowPartDetailsContainer(false);
      setIsValid(true);
      resetMic(4000);
    }
  };

  const resetMic = (time) => {
    setTimeout(() => {
      setDisableSpeaker(false);
    }, [time]);
  };

  useEffect(() => {
    if (props.reset !== DYNAMIC_SEARCH_TYPE) {
      setValue("");
    }
  }, [props.reset]);
  useEffect(() => {
    handleDynamicData();
  }, [value]);

  useEffect(() => {
    let sample = searchTerm
      ?.split(" ")
      ?.join("")
      ?.split("-")
      ?.join("")
      ?.split("/")
      ?.join("")
      ?.split(",")
      ?.join("");
    
    let valid;

      valid = searchTerm
        ? regex.test(sample?.trim()?.slice(0, sample.length))
        : true;
      setValue(sample?.trim()?.slice(0, sample.length));
    

    setIsValid(valid);
    if (!valid) {
      setDisableSpeaker(true);
    }

    stopReco();
    setIsListening(false);
  }, [searchTerm]);

  const handleListing = (e) => {
    e.preventDefault();
    let blankVal = "";
    setValue([...blankVal]);
    setIsListening(true);

    startReco();
  };

  const handleClickAway = () => {
    setShowPartDetailsContainer(false);
  };

  const handleChange = async (e) => {
    let valid = e.target.value ? regex.test(e.target.value) : true;
    setIsValid(valid);
    setValue(e.target.value);
  };
  const getPartData = async (searchArray) => {
    try {
      const res = await competitorInterchangeData({
        partNumbers: searchArray,
        apiName: PARTNUM_SEARCH,
      });
      setLoader(false);
      return res;
    } catch (err) {
      setLoader(false);
      setApiError((val) => ({ ...val, val: true, message: err.message }));
    }
  };
  const handleOnClick = async (item) => {
    setShowPartDetailsContainer(false);
    setDisableSpeaker(false);
    resetMic(1500);
    setLoader(true);
    let res = await getPartData(item.partialPartNumber);
    const data = {
      res: res?.partNumberDetailsList,
      val: true,
      searchType: DYNAMIC_SEARCH_TYPE,

      partNum: item?.partialPartNumber,
      partial: true,
      searchArray: [item?.partialPartNumber],
    };

    props.handleSearch(data);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    } else if (
      (e.key === "Backspace" && !e.target.value) ||
      (e.key === "Delete" && !e.target.value)
    ) {
      resetMic(1500);
    }
  };
  return (
    <>
      <SimpleBackdrop open={loader} />
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="zoom">
          <DialogView
            open={isListening}
            handleClose={() => {
              setIsListening(false);
              stopReco();
            }}
            width={isMobile ? "80%" : "30%"}
            maxWidth={"75em!important"}
            heading={DYNAMIC_SEARCH_HEAD}
            childComponet={
              <DynamicSearchSpeechRecognition
                transcript={searchTerm}
                isMobile={isMobile}
              />
            }
          />
          <Box
            component="fieldset"
            className="fieldset-style-main"
            sx={{ ...POS_RELATIVE }}
          >
            <form className="lg:mx-4 mb-4 ">
              <TextTypography text={DYNAMIC_SEARCH_HEAD} />
              <div className="flex rounded-lg overflow-hidden">
                <DebounceInput
                  ref={microphoneRef}
                  className="min-h-[48px] focus:outline-none grow px-4 bg-[#a3bef124] text-sm"
                  type={"text"}
                  placeholder={PART_NUMBER}
                  value={value}
                  error={!isValid}
                  style={{
                    borderBottom: isValid ? "inherit" : "1px solid red",
                  }}
                  minLength={1}
                  debounceTimeout={1000}
                  onChange={handleChange}
                  onKeyUp={handleKeyPress}
                />

                <button
                  className={
                    disableSpeaker
                      ? "bg-gray-600 text-white hover:bg-opacity-90 min-h-[48px] px-4"
                      : "bg-blue-600 text-white hover:bg-opacity-90 min-h-[48px] px-4"
                  }
                  onClick={handleListing}
                  disabled={disableSpeaker}
                >
                  <FontAwesomeIcon icon={faMicrophone} />
                </button>
              </div>
              {showPartDetailsContainer && (
                <div style={DYNAMIC_LAYER}>
                  <List subheader={<li />}>
                    {dynamicSearchData?.length === 0
                      ? NO_DATA_FOUND_TEXT
                      : dynamicSearchData?.map((item, key) => {
                          return (
                            <ListItem
                              key={key}
                              sx={CURSOR_POINTER}
                              onClick={() => handleOnClick(item)}
                            >
                              <div style={DYNAMIC_ITEM_STYLE}>
                                {item.partialPartNumber}
                              </div>
                            </ListItem>
                          );
                        })}
                  </List>
                </div>
              )}
            </form>
          </Box>
          <SimpleSnackbar apiError={apiError} />
        </div>
      </ClickAwayListener>
    </>
  );
};

export default DynamicSearch;
