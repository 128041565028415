/* eslint-disable  react-hooks/exhaustive-deps */
import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ERR_MSG_VIN_NUM_INVALID,
  FILTER_SEARCH,
  FILTER_SEARCH_TYPE,
  MB_10,
  PARTIAL_FILTER_HEADER,
  PARTIAL_SEARCH_TYPE,
  SEARCH_BY_TEXT,
  SEARCH_FILTER_HEADER,
  VIN_NUMBER_HEAD,
  VIN_SEARCH_TYPE,
} from "../../utils/constants/GlobalConstants";
import { TextTypography } from "../common/typography/TextTypography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchByPart from "./SearchByPart";
import TextInput from "../common/textInput/TextInput";
import { VALID_SEARCH_REGEX } from "../../utils/constants/GlobalFormConstants";
import { vinsearchData } from "../../services/ProductServices";
import SimpleBackdrop from "../common/backdrop/BackDrop";
import SimpleSnackbar from "../common/snackBar/SnackBar";

const SingleSearchBar = (props) => {
  const [section, setSection] = React.useState(VIN_SEARCH_TYPE);
  const [value, setValue] = React.useState(VIN_SEARCH_TYPE);
  const [vinNum, setVinNum] = React.useState();
  const [isValidVin, setIsValidVin] = React.useState(true);
  const [isValid, setIsValid] = React.useState(true);
  const regex = VALID_SEARCH_REGEX;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [apiError, setApiError] = useState({ val: false, messages: "" });
  const [loader, setLoader] = useState(false);
  const [partNum, setPartNum] = React.useState();
  const [filterNum, setfilterNum] = React.useState();

  useEffect(() => {
    if (props.reset !== VIN_SEARCH_TYPE) {
      setVinNum("");
      setApiError((val) => ({ ...val, val: false, message: "" }));
    }
  }, [props.reset]);

  const searchSectionChange = (event) => {
    let val = event.target.value;
    setValue(val);
    setSection(val);
    setApiError((val) => ({ ...val, val: false, message: "" }));
    setVinNum("");
    setPartNum("");
    setfilterNum("")
    setIsValid(true)
    setIsValidVin(true)
  };

  const getVinSearchData = async () => {
    try {
      const res = await vinsearchData({ vin: vinNum });
      return res;
    } catch (err) {
      setApiError((val) => ({ ...val, val: true, message: err.message }));
    }
  };
  const handleOnChange = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (isValidVin) {
      let searchArray = vinNum.split(",");
      let response;
      if (searchArray?.length > 0 && searchArray[0]) {
        response = await getVinSearchData(searchArray);
      }

      if (
        response?.vinItems &&
        (response?.vinItems?.error?.errorCode === "400" ||
          response?.vinItems?.error?.errorCode === "404")
      ) {
        setApiError((val) => ({
          ...val,
          val: true,
          message: response.vinItems.error.message,
        }));
        setLoader(false);
        return;
      }
      const data = {
        res: response?.vinItems,
        val: true,
        searchType: VIN_SEARCH_TYPE,
        partSearch: vinNum,
        searchArray,
        partial: props?.partial,
      };
      setLoader(false);
      props.handleSearch(data);
    }
  else{
      setApiError((val) => ({ ...val, val: true, message: ERR_MSG_VIN_NUM_INVALID }));
      setLoader(false);
    }
  };

  const handleInputChange = (e) => {
    const val = e.target.value;
    const valid = val ? regex.test(val) : true;
    setIsValidVin(valid);
    setVinNum(e.target.value);
  };
  return (
    <div className="zoom">
      <Box
        component="fieldset"
        className="fieldset-style-main"
        sx={{ ...MB_10 }}
      >
        <form className="lg:mx-4">
          <TextTypography text={SEARCH_BY_TEXT} />
          <FormControl>
            <RadioGroup
              row={isMobile ? false : true}
              value={value}
              onChange={(e) => searchSectionChange(e)}
            >
              <FormControlLabel
                value={VIN_SEARCH_TYPE}
                control={<Radio />}
                label={VIN_NUMBER_HEAD}
                testid={"vinradio"}
              />
              <FormControlLabel
                value={FILTER_SEARCH_TYPE}
                control={<Radio />}
                label={SEARCH_FILTER_HEADER}
              />
              <FormControlLabel
                value={PARTIAL_SEARCH_TYPE}
                control={<Radio />}
                label={PARTIAL_FILTER_HEADER}
              />
            </RadioGroup>
          </FormControl>
          {section === VIN_SEARCH_TYPE ? (
            <div>
              <SimpleBackdrop open={loader} />
              <form className="lg: mb-4 mt-2" testid={"vin-form"}>
                <div className="flex rounded-lg overflow-hidden">
                  <TextInput
                    className="min-h-[48px] focus:outline-none grow px-4 bg-[#a3bef124] text-sm"
                    type="text"
                    placeholder="VIN Number"
                    testid={"vinnum"}
                    required={true}
                    value={vinNum}
                    error={!isValidVin}
                    style={{
                      borderBottom: isValidVin ? "inherit" : "1px solid red",
                    }}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <button
                    className="bg-blue-600 text-white hover:bg-opacity-90 min-h-[48px] px-4"
                    type="submit"
                    testid="searchBtn"
                    onClick={(e) => handleOnChange(e)}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>    
              </form>
              <SimpleSnackbar apiError={apiError} />
            </div>
          ) : section === PARTIAL_SEARCH_TYPE ? (
            <SearchByPart
              reset={props.reset}
              handleSearch={(val) => props.handleSearch(val)}
              testid={FILTER_SEARCH}
              partial={true}
              partNum ={partNum}
              setPartNum ={setPartNum}
              filterNum={filterNum}
              setfilterNum={setfilterNum}
              isValid ={isValid}
              setIsValid={setIsValid}
            />
          ) : (
            <SearchByPart
              reset={props.reset}
              handleSearch={(val) => props.handleSearch(val)}
              testid={FILTER_SEARCH}
              partNum ={partNum}
              setPartNum ={setPartNum}
              filterNum={filterNum}
              setfilterNum={setfilterNum}
              isValid ={isValid}
              setIsValid={setIsValid}
            />
          )}
        </form>
      </Box>
    </div>
  );
};

export default SingleSearchBar;
