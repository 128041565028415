import { Link } from "@mui/material";
import React from "react";

const LinkView = (props) => {
  return (
    <Link component="button" variant="body2" onClick={props.onClickLink}>
      {props.children}
    </Link>
  );
};

export default LinkView;
