import React, { memo } from "react";
import DataTable from "../common/dataTable/DataTable";
import { TextTypography } from "../common/typography/TextTypography";
import { useMediaQuery, useTheme } from "@mui/material";
import DataList from "../common/dataList/DataList";
import "./PartDetail.css";
import { COMP_INTERCHANGE } from "../../utils/constants/GlobalConstants";

const CompetitorsInterchange = memo((props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <div className="application-list-text-wrapper">
        <TextTypography
          text={`Competitors Interchanges for : ${props?.partNum}`}
        />
      </div>
      {isMobile ? (
        <DataList
          source={COMP_INTERCHANGE}
          filterSearchRes={props?.competitorsData}
        />
      ) : (
        <DataTable
          source={COMP_INTERCHANGE}
          filterSearchRes={props?.competitorsData}
        />
      )}
    </>
  );
});
export default CompetitorsInterchange;
