// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  position: relative;
  display: inline-block;
}

.image {
  width: 330px;
  margin-left: 30%;
  height: auto;
}

.imageForMobile {
  width: 280px;
  margin: auto;
  height: 200px;
}

.magnified-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 450px;
  height: 450px;
  margin-left: 20%;
  border: 2px solid #ccc;
  background-size: 600px 600px;
  background-repeat: no-repeat;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/imageMagnify/ImageMagnify.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,4BAA4B;EAC5B,4BAA4B;EAC5B,oBAAoB;AACtB","sourcesContent":[".image-container {\n  position: relative;\n  display: inline-block;\n}\n\n.image {\n  width: 330px;\n  margin-left: 30%;\n  height: auto;\n}\n\n.imageForMobile {\n  width: 280px;\n  margin: auto;\n  height: 200px;\n}\n\n.magnified-image {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 450px;\n  height: 450px;\n  margin-left: 20%;\n  border: 2px solid #ccc;\n  background-size: 600px 600px;\n  background-repeat: no-repeat;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
