import React, { useEffect, useState } from "react";
import { AppBody2Text } from "../appHeaders/AppHeaders";
import "./ErrorView.css";
import {
  ERR_MSG_PART_NO,
  ERROR_MSG,
  COMPETITOR_INTERCHANGE_TYPE,
  VIN_SEARCH_TYPE,
  ERR_MSG_VIN_NUM,
} from "../../../utils/constants/GlobalConstants";

const ErrorView = (props) => {
  const [errData, setErrData] = useState(props.data);

  useEffect(() => {
    setErrData(props.data);
  }, [props]);

  const getErrInfo = () => {
    const result = errData.searchType.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  return (
    errData && (
      <div className="errorViewContainer">
        <AppBody2Text
          text={
            errData.searchType === COMPETITOR_INTERCHANGE_TYPE
              ? getErrInfo() + `: ${ERROR_MSG}.`
              : errData.searchType === VIN_SEARCH_TYPE
              ? getErrInfo() + `:  ${ERR_MSG_VIN_NUM}.`
              : getErrInfo() + `:  ${ERR_MSG_PART_NO}.`
          }
        />
      </div>
    )
  );
};

export default ErrorView;
