import React from "react";
import Navbar from "../common/header/NavBar";
import {
  APP_TITLE,
  MAIN_HEAD_TABS,
} from "../../utils/constants/GlobalConstants";

const Header = () => {
  return <Navbar title={APP_TITLE} pages={MAIN_HEAD_TABS} />;
};

export default Header;
