import React from "react";
import { MAIL_ASSET_STYLE, ROW_FLEX } from "../../utils/constants/GlobalConstants";
import { GMAIL, MAIL_CLIENTS, OUTLOOK } from "../../utils/constants/GlobalFormConstants";

const ReportIssue = () => {
  const handleOpenClient = (client) => {
    if (client === "gmail") {
      window
        .open(
          GMAIL,
          "_blank"
        )
        .focus();
    } else if (client === "outlook") {
      window
        .open(
          OUTLOOK,
          "_blank"
        )
        .focus();
    } 
  };
  return (
    <div
      style={ROW_FLEX}
    >
      {MAIL_CLIENTS.map((item, index) => (
        <img
          key={index}
          src={item.src}
          alt={item.client}
          style={MAIL_ASSET_STYLE}
          onClick={() => handleOpenClient(item.client)}
          testid = "onClickClient"
        />
      ))}
    </div>
  );
};

export default ReportIssue;
